import router from '@/router';

import { addError } from '@/store/errors';
import { isFullScreen, toggleFullScreen } from '@/store/fullscreen';

import { tokenClear } from '@/store/auth';
import { disconnect } from '@/store/video/room';

export default function useLogout(message = null) {
    if (message) {
        addError('Login', new Error(message));
    }

    if (isFullScreen.value) {
        toggleFullScreen();
    }

    disconnect().then(() => {
        tokenClear().then(() => {
            router.push({ name: 'login' });
        });
    });
}
