<template>
    <transition name='controls-fade'>
        <header
            v-if='isVisible()'
            :class='{ "position-absolute": isFullScreen }'
            @touchmove='isFullScreen && hasActivity($event)'
            @mousemove='isFullScreen && hasActivity($event)'
        >
            <nav class='navbar navbar-dark bg-dark'>
                <div class='container-fluid'>
                    <div class='navbar-brand navbar-logo'>
                        <Logo theme='white' width='60px' fixed />
                    </div>

                    <slot name='navbarDetails' :details='details'>
                        {{ details }}
                    </slot>

                    <slot name='navbarActions' :actions='actions'>
                        {{ actions }}
                    </slot>

                    <slot name='navbarUser' :user='user'>
                        {{ user }}
                    </slot>
                </div>
            </nav>
        </header>
    </transition>
</template>

<script>
import Logo from '@/components/partials/Logo.vue';

import { isFullScreen } from '@/store/fullscreen';
import { activity, hasActivity } from '@/store/controlActivity';

export default {
    name: 'Navbar',
    components: {
        Logo,
    },
    props: {
        details: {
            type: String,
            default: null,
        },
        actions: {
            type: String,
            default: null,
        },
        user: {
            type: String,
            default: null,
        },
    },
    setup() {
        function isVisible() {
            if (!isFullScreen.value) return true;

            return activity.value;
        }

        return {
            isVisible,
            isFullScreen,
            hasActivity,
        };
    },
};
</script>

<style lang="scss">
header {
    z-index: 2;
    width: 100%;
    height: 5rem;

    & .navbar {
        height: inherit;
        padding: 0.1rem;
        padding-left: env(safe-area-inset-left);
        padding-right: env(safe-area-inset-right);
    }
}
</style>
