<template>
    <CenterLayout>
        <div id='content' class='col'>
            <div id='login-wrapper' class='m-auto'>
                <div id='login-content' class='py-4'>
                    <Logo />
                    <h2 class='py-4 w-100 text-center'>Sign-in to Livestream</h2>

                    <div v-if='errorLogin' class='alert alert-danger'>
                        {{ errorLogin?.message }}
                    </div>

                    <form class='needs-validation' novalidate @submit.prevent='onSubmit'>
                        <div class='form-floating mb-3'>
                            <input
                                id='username'
                                v-model='form.username'
                                :class='{ "is-invalid": errorUsername }'
                                class='form-control form-control-lg'
                                type='text'
                                placeholder='Username'
                                maxlength='24'
                                required
                                autofocus
                            >
                            <label for='username'>Username</label>

                            <div class='invalid-feedback'>
                                {{ errorUsername?.message }}
                            </div>
                        </div>

                        <div class='form-floating mb-3'>
                            <input
                                id='password'
                                v-model='form.password'
                                :class='{ "is-invalid": errorPassword || errorRoom }'
                                class='form-control form-control-lg'
                                type='password'
                                inputmode='numeric'
                                pattern='[0-9]{6}'
                                placeholder='Password'
                                required
                            >
                            <label for='password'>Password</label>

                            <div class='invalid-feedback'>
                                {{ errorPassword?.message }}
                                {{ errorRoom?.message }}
                            </div>
                        </div>

                        <div class='form-group d-grid'>
                            <button class='btn btn-primary btn-lg btn-block' type='submit'>
                                Login
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </CenterLayout>
</template>

<script>
import { reactive } from 'vue';
import { useRouter } from 'vue-router';

import { setTheme } from '@/store/theme';
import { tokenNew } from '@/store/auth';
import { getError, clearError } from '@/store/errors';

import CenterLayout from '@/components/layouts/CenterLayout.vue';
import Logo from '@/components/partials/Logo.vue';

export default {
    name: 'Login',
    components: {
        CenterLayout,
        Logo,
    },
    setup() {
        setTheme('#ffffff');

        const router = useRouter();
        const form = reactive({
            username: '',
            password: '',
        });

        const errorLogin = getError('Login');
        const errorUsername = getError('username');
        const errorPassword = getError('password');
        const errorRoom = getError('room');

        function onSubmit() {
            clearError();

            tokenNew(form).then(() => {
                clearError();

                router.push({ name: 'interaction' });
            });
        }

        return {
            form,
            onSubmit,
            errorLogin,
            errorUsername,
            errorPassword,
            errorRoom
        };
    },
};
</script>

<style lang="scss">
@media (width >= 576px) {
    #center-layout #content {
        display: flex;
        align-content: center;
    }

    #login-wrapper {
        width: 400px;

        & #login-content {
            padding: 30px 15px;
            min-height: 550px;
        }
    }
}
</style>
