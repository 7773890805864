import { reactive, toRefs } from 'vue';

import { debounce } from '@/hooks/utils';

const state = reactive({
    activity: true
});

const { activity } = toRefs(state);

function setActivity(newActivity) {
    state.activity = newActivity;
}

const activityCheck = debounce(() => {
    setActivity(false);
}, 2500);

const hasActivity = () => {
    state.activity = true;
    activityCheck();
};

export { activity, setActivity, hasActivity };
