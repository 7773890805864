<template>
    <CenterLayout>
        <div id='content' class='col'>
            <div id='interaction-wrapper' class='m-auto'>
                <div id='interaction-content' class='py-4'>
                    <Logo />
                    <h3 class='pt-4 w-100 text-center'>You are connecting to</h3>
                    <h2 class='pt-2 pb-3 w-100 text-center'>{{ roomDetails.title }}</h2>

                    <div class='d-grid'>
                        <p class='text-center fs-5'>The livestream and all audio from users will be recorded.</p>

                        <button
                            type='button'
                            class='btn btn-primary btn-lg btn-block'
                            :disabled='!auth'
                            @click='confirm()'
                        >
                            Join Room
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </CenterLayout>
</template>

<script>
import { useRouter } from 'vue-router';
import { watch } from 'vue';

import { auth, roomDetails } from '@/store/auth';
import { enableAllowMediaPlayback } from '@/store/video/media';
import { messagesSeed } from '@/store/chat';
import { setTheme } from '@/store/theme';

import useIsUserActive from '@/hooks/userActive';
import useLogout from '@/hooks/logout';

import CenterLayout from '@/components/layouts/CenterLayout.vue';
import Logo from '@/components/partials/Logo.vue';

export default {
    name: 'Interaction',
    components: {
        CenterLayout,
        Logo,
    },
    setup() {
        setTheme('#ffffff');

        messagesSeed();

        const router = useRouter();

        function confirm() {
            enableAllowMediaPlayback();

            router.push({ name: 'livestream' });
        }

        const isUserActive = useIsUserActive();
        watch(isUserActive, () => {
            if (!isUserActive.value) {
                useLogout('You have been disconnected due to inactivity.');
            }
        });

        return {
            auth,
            roomDetails,
            confirm,
        };
    },
};
</script>

<style lang="scss">
@media (width >= 576px) {
    #interaction-wrapper {
        width: 400px;

        & #interaction-content {
            padding: 30px 15px;
        }
    }
}
</style>
