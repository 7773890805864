import { createRouter, createWebHistory } from 'vue-router';
import {
    requireAuth,
    requireGuest,
    requireInteraction,
} from '@/router/middleware';

import Login from '@/views/Login.vue';
import Livestream from '@/views/Livestream.vue';
import Interaction from '@/views/Interaction.vue';
import NotFound from '@/views/NotFound.vue';

export default createRouter({
    history: createWebHistory(),
    base: import.meta.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'livestream',
            component: Livestream,
            beforeEnter: [requireAuth, requireInteraction],
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            beforeEnter: requireGuest,
        },
        {
            name: 'interaction',
            component: Interaction,
            beforeEnter: requireAuth,
        },
        {
            path: '/:pathMatch(.*)*',
            name: 'not-found',
            component: NotFound,
        },
        {
            path: '/:pathMatch(.*)',
            name: 'bad-not-found',
            component: NotFound,
        },
    ],
});
