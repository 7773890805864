<template>
    <div ref='element' class='simplebar'>
        <div class='simplebar-wrapper'>
            <div class='simplebar-height-auto-observer-wrapper'>
                <div class='simplebar-height-auto-observer' />
            </div>
            <div class='simplebar-mask'>
                <div class='simplebar-offset'>
                    <div
                        ref='scrollElement'
                        class='simplebar-content-wrapper'
                        v-bind='$attrs'
                    >
                        <div ref='contentElement' class='simplebar-content'>
                            <slot />
                        </div>
                    </div>
                </div>
            </div>
            <div class='simplebar-placeholder' />
        </div>
        <div class='simplebar-track simplebar-horizontal'>
            <div class='simplebar-scrollbar' />
        </div>
        <div class='simplebar-track simplebar-vertical'>
            <div class='simplebar-scrollbar' />
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';

import SimpleBar from 'simplebar';

export default {
    name: 'SimpleBar',
    setup() {
        const element = ref(null);
        const scrollElement = ref(null);
        const contentElement = ref(null);

        onMounted(() => {
            const options = SimpleBar.getOptions(element.value?.attributes);
            new SimpleBar(element.value, options);
        });

        return {
            element,
            scrollElement,
            contentElement
        };
    },
};
</script>

<style src="simplebar/dist/simplebar.css" />
<style>
    .simplebar {
        margin: 0 0.25rem;
    }

    .simplebar-content {
        margin: 0 0.25rem;
    }

    .simplebar-scrollbar::before {
        background: white;
    }
</style>
