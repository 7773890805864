<template>
    <div id='actions' class='navbar gap-2 me-2'>
        <button
            v-if='isPublishingLocalTracks'
            class='btn'
            :class='{
                "btn-success": isLocalAudioEnabled,
                "btn-danger": !isLocalAudioEnabled,
            }'
            type='button'
            @click='toggleAudio()'
        >
            <IconBase>
                <MicFill v-if='isLocalAudioEnabled' />
                <MicMuteFill v-else />
            </IconBase>
        </button>

        <button
            v-if='isHost'
            class='btn'
            type='button'
            :class='{ "btn-danger": isPublishing, "btn-success": !isPublishing }'
            :disabled='!isConnected'
            @click='togglePublishingStatus()'
            v-html='isPublishing ? "End Stream" : "Start Stream"'
        />
        <button
            v-else
            class='btn'
            type='button'
            :class='{ "btn-danger": isPublishing, "btn-success": !isPublishing }'
            :disabled='!isConnected'
            @click='togglePublishingStatus()'
            v-html='isPublishing ? "Hangup" : "Voice"'
        />

        <button
            v-if='!isFullScreen'
            class='btn btn-secondary'
            type='button'
            :class='{ active: settingsVisible }'
            @click='changeSettingsVisible()'
        >
            <IconBase>
                <GearFill />
            </IconBase>
        </button>
    </div>
</template>

<script>
import { ref, computed } from 'vue';

import { isHost } from '@/store/auth';
import { unpublishLocalTracks } from '@/store/video/localTracks';
import { setPrompt } from '@/store/prompt';
import { localParticipant, localParticipantChild } from '@/store/video/participants';
import { endCall } from '@/store/video/phone';
import { isFullScreen } from '@/store/fullscreen';
import { isConnected } from '@/store/video/room';
import { settingsVisible, changeSettingsVisible } from '@/store/settings';

import usePublications from '@/hooks/video/publications';
import useLocalAudioToggle from '@/hooks/video/localAudioToggle';

import IconBase from '@/components/partials/IconBase.vue';
import MicFill from '@/components/icons/MicFill.vue';
import MicMuteFill from '@/components/icons/MicMuteFill.vue';
import GearFill from '@/components/icons/GearFill.vue';

export default {
    name: 'Actions',
    components: {
        IconBase,
        MicFill,
        MicMuteFill,
        GearFill,
    },
    setup() {
        const { audioPublication, videoPublication } = usePublications(localParticipant);

        const lastAudioToggle = ref(0);
        const isPublishingLocalTracks = computed(() => audioPublication.value || videoPublication.value);
        const isPublishingPhoneCall = computed(() => localParticipantChild.value);
        const isPublishing = computed(() => isPublishingLocalTracks.value || isPublishingPhoneCall.value);

        function togglePublishingStatus() {
            if (isPublishingLocalTracks.value) return unpublishLocalTracks(localParticipant);
            if (isPublishingPhoneCall.value) return endCall();

            return setPrompt('JoinPrompt');
        }

        const { isLocalAudioEnabled, toggleLocalAudioEnabled } = useLocalAudioToggle();

        function toggleAudio() {
            if (Date.now() - lastAudioToggle.value > 200) {
                lastAudioToggle.value = Date.now();

                toggleLocalAudioEnabled();
            }
        }

        return {
            lastAudioToggle,
            isPublishingLocalTracks,
            isPublishing,
            isHost,
            isConnected,
            isLocalAudioEnabled,
            toggleAudio,
            togglePublishingStatus,
            settingsVisible,
            changeSettingsVisible,
            isFullScreen,
        };
    },
};
</script>
