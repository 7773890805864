import { reactive, watchEffect, toRefs } from 'vue';
import fscreen from 'fscreen';

export default function useFullScreenToggle(element = null) {
    const state = reactive({
        isFullScreen: !!fscreen.fullscreenElement,
    });

    const setIsFullScreen = (newIsFullScreen) => {
        state.isFullScreen = newIsFullScreen;
    };

    watchEffect((onInvalidate) => {
        const onFullScreenChange = () =>
            setIsFullScreen(!!fscreen.fullscreenElement);
        fscreen.addEventListener('fullscreenchange', onFullScreenChange);

        onInvalidate(() => {
            fscreen.removeEventListener('fullscreenchange', onFullScreenChange);
        });
    });

    const toggleFullScreen = () => {
        state.isFullScreen
            ? fscreen.exitFullscreen()
            : fscreen.requestFullscreen(element || document.documentElement);
    };

    const supportsFullScreen = () => {
        return fscreen.fullscreenEnabled;
    };

    return {
        ...toRefs(state),
        toggleFullScreen,
        supportsFullScreen,
    };
}
