import { reactive, ref } from 'vue';

import { localParticipant } from '@/store/video/participants';
import {
    localVideoTrack,
    publishLocalTracks,
    unpublishLocalTracks,
} from '@/store/video/localTracks';

export default function useLocalVideoToggle() {
    const previousDeviceIdRef = ref();

    const state = reactive({
        isPublishing: false,
    });

    const setIsPublishing = (newIsPublishing) => {
        state.isPublishing = newIsPublishing;
    };

    const toggleVideoEnabled = () => {
        if (!state.isPublishing) {
            if (localVideoTrack.value) {
                previousDeviceIdRef.value = localVideoTrack.value.mediaStreamTrack.getSettings().deviceId;
                unpublishLocalTracks(localParticipant);
            } else {
                setIsPublishing(false);
                publishLocalTracks({
                    deviceId: { exact: previousDeviceIdRef.value },
                }).finally(() => setIsPublishing(false));
            }
        }
    };

    return toggleVideoEnabled;
}
