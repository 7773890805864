import { shallowRef } from 'vue';
import apiClient from '@/libraries/axios';

const activeCall = shallowRef(null);

const callMe = (number) => {
    return apiClient
        .post('call/me', { number: number })
        .then((response) => {
            activeCall.value = response.callSid;
        })
        .catch((error) => {
            console.log(error);

            return Promise.reject(error.response);
        });
};

const endCall = () => {
    return apiClient
        .post('call/end', { callSid: activeCall.value })
        .catch((error) => {
            console.log(error);

            return Promise.reject(error.response);
        });
};

const killCall = (username) => {
    return apiClient
        .post('call/kill', { username: username })
        .catch((error) => {
            console.log(error);

            return Promise.reject(error.response);
        });
};

export {
    callMe,
    endCall,
    killCall
};
