import { computed, watchEffect, shallowRef } from 'vue';

export default function usePublications(participant) {
    const publications = shallowRef([]);
    const audioPublication = computed(() =>
        publications.value.find((p) => p.kind === 'audio')
    );
    const videoPublication = computed(() =>
        publications.value.find((p) => p.kind === 'video')
    );
    const dataPublication = computed(() =>
        publications.value.find((p) => p.kind === 'data')
    );

    const setPublications = (newPublications) => {
        publications.value = newPublications;
    };

    watchEffect((onInvalidate) => {
        if (!participant.value) return;

        setPublications(Array.from(participant.value.tracks.values()));

        const publicationAdded = (publication) =>
            setPublications([...publications.value, publication]);
        const publicationRemoved = (publication) =>
            setPublications(publications.value.filter((p) => p !== publication));

        participant.value.on('trackPublished', publicationAdded);
        participant.value.on('trackUnpublished', publicationRemoved);

        onInvalidate(() => {
            participant.value?.off('trackPublished', publicationAdded);
            participant.value?.off('trackUnpublished', publicationRemoved);
        });
    });

    return {
        publications,
        audioPublication,
        videoPublication,
        dataPublication
    };
}
