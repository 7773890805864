<template v-if='audioTrack'>
    <div class='audio-level row g-0 mb-3'>
        <div
            v-for='bar in bars'
            :key='bar'
            :class='{ "bg-success": bar < activeBars }'
            class='col audio-bars'
        />
    </div>
</template>

<script>
import { watch, ref, toRef } from 'vue';

import useAudioLevelIndicator from '@/hooks/video/audioLevelIndicator';

export default {
    name: 'AudioLevel',
    props: {
        audioTrack: {
            type: null,
            required: true,
        },
        bars: {
            type: Number,
            required: false,
            default: 10,
        },
    },
    setup(props) {
        const audioTrack = toRef(props, 'audioTrack');
        const bars = toRef(props, 'bars');

        const { volume } = useAudioLevelIndicator(audioTrack);

        const activeBars = ref(0);

        watch(volume, () => {
            activeBars.value = Math.round(volume.value / bars.value);
        });

        return {
            activeBars,
        };
    },
};
</script>

<style>
.audio-bars {
    width: calc(10% - 10px);
    background-color: #e6e7e8;
    height: 10px;
    margin: 5px;
}
</style>
