import { watchEffect } from 'vue';

import { localDataTrack } from '@/store/video/localTracks';

let monitors = [];
let monitorCallbacks = new Map();

export default function useDataTrack() {
    const monitorDataTrack = (dataTrack, {type, participant = null}, callback) => {
        return watchEffect((onInvalidate) => {
            if (!dataTrack.value) return;

            const monitorMessages = (data) => {
                const message = JSON.parse(data);
                const monitor = monitorCallbacks.get(message.dataSid + message.type);

                if (monitor) {
                    if (message.sid === null || monitor.participant.value === null || monitor.participant.value.sid === message.sid) {
                        monitor.callback(message.data);
                    }
                }
            };

            monitorCallbacks.set(dataTrack.value.name + type, {
                participant,
                callback
            });

            if (!monitors.includes(dataTrack.value.name)) {
                monitors.push(dataTrack.value.name);

                dataTrack.value.on('message', monitorMessages);
    
                onInvalidate(() => {
                    monitors = monitors.filter(monitor => monitor === dataTrack.value?.name);
                    monitorCallbacks.delete(dataTrack.value?.name + type);

                    dataTrack.value?.off('message', monitorMessages);
                });
            }
        });
    };

    const dataTrackSignal = ({type, data = null, sid = null}) => {
        return new Promise((resolve) => {
            localDataTrack.value.send(
                JSON.stringify({
                    dataSid: localDataTrack.value.name,
                    type: type,
                    data: data,
                    sid: sid
                })
            );
    
            return resolve();
        }).catch((error) => {
            console.log(error);
    
            return Promise.reject(error.response);
        });
    };

    return { monitorDataTrack, dataTrackSignal };
}

// import { watchEffect } from 'vue';

// import { localDataTrack } from '@/store/video/localTracks';

// export default function useDataTrack() {
//     const monitorDataTrack = (dataTrack, {type, participant = null}, callback) => {
//         return watchEffect((onInvalidate) => {
//             if (!dataTrack.value) return;

//             const monitorMessages = (data) => {
//                 const message = JSON.parse(data);

//                 if (message.type === type) {
//                     if (message.sid === null || participant.value === null || participant.value.sid === message.sid) {
//                         callback(message.data);
//                     }
//                 }
//             };

//             dataTrack.value.on('message', monitorMessages);

//             onInvalidate(() => {
//                 dataTrack.value?.off('message', monitorMessages);
//             });
//         });
//     };

//     const dataTrackSignal = ({type, data = null, sid = null}) => {
//         return new Promise((resolve) => {
//             localDataTrack.value.send(
//                 JSON.stringify({
//                     type: type,
//                     data: data,
//                     sid: sid
//                 })
//             );
    
//             return resolve();
//         }).catch((error) => {
//             console.log(error);
    
//             return Promise.reject(error.response);
//         });
//     };

//     return { monitorDataTrack, dataTrackSignal };
// }
