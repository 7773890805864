import { watchEffect, ref } from 'vue';

import { jwtDecode } from '@/hooks/utils';
import { token, me } from '@/store/auth';

export default function useTokenValid() {
    const timeoutId = ref(0);

    watchEffect((onInvalidate) => {
        if (!token.value) return;

        const jwt = jwtDecode(token.value);

        if (timeoutId.value) {
            clearTimeout(timeoutId.value);
        }

        const timeUntilExpiration = (jwt.exp - Math.floor(Date.now() / 1000)) * 1000 - 20000;
        const timeoutID = setTimeout(() => me(), timeUntilExpiration);
        timeoutId.value = timeoutID;

        onInvalidate(() => {
            if (!timeoutId.value) return;

            clearTimeout(timeoutId.value);
        });
    });
}
