<template>
    <Transition name='prompt'>
        <div v-if='hasPrompt' id='prompt' @click.self='closePrompt'>
            <component :is='prompt' :key='prompt.name' @unmounted='closePrompt' />
        </div>
    </Transition>
</template>

<script>
import { prompt, hasPrompt, closePrompt } from '@/store/prompt';

export default {
    name: 'Prompt',
    setup() {
        return {
            prompt,
            hasPrompt,
            closePrompt,
        };
    },
};
</script>

<style lang="scss">
.prompt-enter-active,
.prompt-leave-active {
    transition: opacity 0.2s ease;
}

.prompt-enter-from,
.prompt-leave-to {
    opacity: 0;
}

#prompt {
    z-index: 5;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
        content: "";
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.3;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
}
</style>
