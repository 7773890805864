import { reactive, watchEffect, toRefs } from 'vue';

import useMediaStreamTrack from '@/hooks/video/mediaStreamTrack';
import { publishingOptions } from '@/hooks/options';

import { localVideoTrack } from '@/store/video/localTracks';

export default function useFlipCamera() {
    const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);

    const state = reactive({
        supportsFacingMode: null,
    });

    const setSupportsFacingMode = (newSupportsFacingMode) => {
        state.supportsFacingMode = newSupportsFacingMode;
    };

    watchEffect(() => {
        const currentFacingMode = mediaStreamTrack.value?.getSettings().facingMode;
        if (currentFacingMode && state.supportsFacingMode === null) {
            setSupportsFacingMode(true);
        }
    });

    const toggleFacingMode = () => {
        const newFacingMode =
      mediaStreamTrack.value?.getSettings().facingMode === 'user'
          ? 'environment'
          : 'user';
        localVideoTrack.value.restart({
            ...publishingOptions,
            facingMode: newFacingMode,
        });
    };

    return {
        ...toRefs(state),
        toggleFacingMode,
    };
}
