<template>
    <div
        id='presentation'
        :class='{ presentationbg: isConnected && videoPublication && videoVisible }'
    >
        <template v-if='isConnected && isHostPublishing'>
            <AudioTrack
                v-if='!isLocal && audioPublication && audioVisible'
                :publication='audioPublication'
                :is-local='isLocal'
            />
            <VideoTrack
                v-if='videoPublication && videoVisible'
                :publication='videoPublication'
                :is-local='isLocal'
            />
            <Watermark v-else>You have hidden the video.</Watermark>

            <VideoControls
                v-model:audio-visible='audioVisible'
                v-model:video-visible='videoVisible'
                :is-local='isLocal'
            />
        </template>
        <template v-else>
            <template v-if='!isHost'>
                <Watermark>The event will start soon.</Watermark>
            </template>
            <template v-else>
                <Watermark>You may start the event at anytime.</Watermark>
            </template>
        </template>
    </div>
</template>

<script>
import { computed, ref, watch } from 'vue';

import { isHost } from '@/store/auth';
import { isConnected } from '@/store/video/room';
import { roomHost, localParticipant } from '@/store/video/participants';
import { isFullScreen, toggleFullScreen } from '@/store/fullscreen';

import usePublications from '@/hooks/video/publications';
import useTrack from '@/hooks/video/track';
import useRoomDetails from '@/hooks/video/roomDetails';
import useLogout from '@/hooks/logout';
import useLocalAudioToggle from '@/hooks/video/localAudioToggle';
import useDataTrack from '@/hooks/video/dataTrack';

import Watermark from '@/components/partials/Watermark.vue';
import AudioTrack from '@/components/video/AudioTrack.vue';
import VideoTrack from '@/components/video/VideoTrack.vue';
import VideoControls from '@/components/video/VideoControls.vue';

export default {
    name: 'Presentation',
    components: {
        Watermark,
        AudioTrack,
        VideoTrack,
        VideoControls,
    },
    setup() {
        const isLocal = computed(() => roomHost.value && localParticipant.value && roomHost.value.sid === localParticipant.value.sid);

        const { audioPublication, videoPublication, dataPublication } = usePublications(roomHost);
        const dataTrack = useTrack(dataPublication);
        const { monitorDataTrack } = useDataTrack();

        useRoomDetails(dataTrack);

        const { isLocalAudioEnabled, toggleLocalAudioEnabled } = useLocalAudioToggle();
        monitorDataTrack(dataTrack, {type: 'mute', participant: localParticipant}, () => isLocalAudioEnabled.value && toggleLocalAudioEnabled());
        monitorDataTrack(dataTrack, {type: 'kick', participant: localParticipant}, () => {
            useLogout('You were kicked from the room.');
        });

        const isHostPublishing = computed(() => audioPublication.value || videoPublication.value);
        watch(isHostPublishing, () => {
            if (!isHostPublishing.value && isFullScreen.value) {
                toggleFullScreen();
            }
        });

        return {
            isHost,
            roomHost,
            audioPublication,
            videoPublication,
            isHostPublishing,
            videoVisible: ref(!isHost.value),
            audioVisible: ref(true),
            isLocal,
            isConnected,
        };
    },
};
</script>

<style lang="scss">
.presentationbg {
    background-color: #000;
}
</style>
