<template>
    <div class='sidebar-wrapper'>
        <div class='sidebar-tabs'>
            <ul class='nav nav-pills nav-fill' role='tablist'>
                <li class='nav-item' role='presentation'>
                    <a
                        class='nav-link'
                        :class='{ active: sidebarTab === 0 }'
                        @click='sidebarTab = 0'
                    >Chat</a>
                </li>
                <li class='nav-item' role='presentation'>
                    <a
                        class='nav-link'
                        :class='{ active: sidebarTab === 1 }'
                        @click='sidebarTab = 1'
                    >Users</a>
                </li>
            </ul>
        </div>

        <div id='sidebar-tabContent' class='tab-content sidebar-content'>
            <div
                id='sidebar-chat'
                class='tab-pane sidebar-tab'
                :class='{ active: sidebarTab === 0 }'
            >
                <keep-alive>
                    <Chat />
                </keep-alive>
            </div>

            <div
                id='sidebar-users'
                class='tab-pane sidebar-tab'
                :class='{ active: sidebarTab === 1 }'
            >
                <keep-alive>
                    <UserList />
                </keep-alive>
            </div>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue';

import { sidebar, changeSidebar } from '@/store/settings';

import Chat from '@/components/sidebar/Chat.vue';
import UserList from '@/components/sidebar/UserList.vue';

export default {
    name: 'Sidebar',
    components: {
        Chat,
        UserList,
    },
    setup() {
        const sidebarTab = computed({
            get: () => (sidebar.value === null ? 0 : sidebar.value),
            set: (tabIndex) => {
                changeSidebar(tabIndex);
            },
        });

        return {
            sidebar,
            sidebarTab,
        };
    },
};
</script>

<style lang="scss">
.sidebar-wrapper {
    word-wrap: break-word;
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #40464c;
    padding-right: env(safe-area-inset-right);

    & .nav-link {
        color: #fff;
    }
}

.sidebar-content {
    overflow: hidden;
    display: flex;
    flex-grow: 1;
}

.sidebar-tabs {
    /* Fixes clipping issue */
    flex-shrink: 0;
    padding: 0.75rem 1.25rem;
    border-bottom: 1px solid rgb(0 0 0 / 12.5%);
}

.sidebar-tab {
    color: #fff;
    flex-grow: 1;

    &.active {
        display: flex;
    }
}
</style>
