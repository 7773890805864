import { reactive, watchEffect, toRef } from 'vue';

export default function usePublicationIsTrackEnabled(publication) {
    const state = reactive({
        isTrackEnabled: false,
    });

    const setIsTrackEnabled = (newIsTrackEnabled) => {
        state.isTrackEnabled = newIsTrackEnabled;
    };

    watchEffect((onInvalidate) => {
        setIsTrackEnabled(
            publication.value ? publication.value.isTrackEnabled : false
        );

        if (publication.value) {
            const setTrackEnabled = () => setIsTrackEnabled(true);
            const setTrackDisabled = () => setIsTrackEnabled(false);

            publication.value.on('trackEnabled', setTrackEnabled);
            publication.value.on('trackDisabled', setTrackDisabled);

            onInvalidate(() => {
                publication.value?.off('trackEnabled', setTrackEnabled);
                publication.value?.off('trackDisabled', setTrackDisabled);
            });
        }
    });

    return toRef(state, 'isTrackEnabled');
}
