<template>
    <span ref='audioWrapper' class='audio d-none' />
</template>

<script>
import { watchEffect, ref, toRef } from 'vue';

import { getAudioElement, releaseAudioElement } from '@/store/video/media';
import useTrack from '@/hooks/video/track';

export default {
    name: 'AudioTrack',
    props: {
        publication: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const audioTrack = useTrack(toRef(props, 'publication'));
        const audioElement = getAudioElement(props.publication.sid);
        const audioWrapper = ref(null);

        watchEffect((onInvalidate) => {
            if (!audioTrack.value || !audioWrapper.value) return;

            audioTrack.value.attach(audioElement);
            audioElement.setAttribute(
                'data-cy-audio-track-name',
                audioTrack.value.name
            );
            audioWrapper.value.appendChild(audioElement);

            onInvalidate(() => {
                audioTrack.value?.detach();

                if (audioWrapper.value.contains(audioElement)) {
                    releaseAudioElement(audioElement);
                    audioElement.remove();

                    // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
                    // See: https://github.com/twilio/twilio-video.js/issues/1528
                    audioElement.srcObject = null;
                }
            });
        });

        return {
            audioTrack,
            audioWrapper,
        };
    },
};
</script>

<style>
audio {
    display: none;
}
</style>
