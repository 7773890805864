import { watch, watchEffect, shallowRef } from 'vue';

export default function useParticipants(room) {
    const participants = shallowRef(Array.from(room.value?.participants.values() ?? []));

    const setParticipants = (newParticipants) => {
        participants.value = newParticipants;
    };

    // Seed participants if there are some currently connected.
    // This prevents us having to set participants after room connection
    // which would be gross.
    watch(room, () => {
        if (room.value && !participants.value.length) {
            setParticipants(Array.from(room.value.participants.values()));
        }
    });

    watchEffect((onInvalidate) => {
        if (room.value) {
            const participantConnected = (participant) =>
                setParticipants([...participants.value, participant]);
            const participantDisconnected = (participant) =>
                setParticipants(participants.value.filter((p) => p !== participant));
    
            room.value.on('participantConnected', participantConnected);
            room.value.on('participantDisconnected', participantDisconnected);
    
            onInvalidate(() => {
                room.value?.off('participantConnected', participantConnected);
                room.value?.off('participantDisconnected', participantDisconnected);
            });
        }
    });

    return participants;
}
