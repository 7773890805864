import useDataTrack from '@/hooks/video/dataTrack';

import { setRoomDetails } from '@/store/auth';

export default function useRoomDetails(dataTrack) {
    const { monitorDataTrack } = useDataTrack();

    monitorDataTrack(dataTrack, {type: 'roomDetails'}, (data) =>
        setRoomDetails(data)
    );
}
