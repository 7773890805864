import { computed, reactive, shallowRef } from 'vue';

const state = reactive({
    errors: shallowRef([]),
});

const hasError = (type) => {
    return state.errors.find((e) => e.type === type);
};

const getError = (type) => {
    return computed(() => state.errors.find((e) => e.type === type)?.error);
};

const addError = (type, error) => {
    state.errors = [...state.errors, { type: type, error: error }];

    return error;
};

const clearError = (type = null) => {
    state.errors =
    type === null ? [] : state.errors.filter((e) => e.type !== type);
};

export { hasError, getError, addError, clearError };
