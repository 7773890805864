import { me, auth, token, roomDetails } from '@/store/auth';
import { allowMediaPlayback } from '@/store/video/media';

export const requireAuth = (to, from, next) => {
    if (token.value === null) {
        return next({ name: 'login' });
    }

    if (auth.value === null || roomDetails.value === null) {
        return me().then(() => next());
    } else {
        return next();
    }
};

export const requireGuest = (to, from, next) => {
    if (auth.value === true && token.value === true) {
        return next({ name: 'livestream' });
    } else {
        return next();
    }
};

export const requireInteraction = (to, from, next) => {
    if (!allowMediaPlayback.value) {
        return next({ name: 'interaction', params: [to.path], replace: true });
    } else {
        return next();
    }
};
