import { reactive, computed, watchEffect, shallowRef, toRefs } from 'vue';
import { interval } from 'd3-timer';

import { audioContext } from '@/store/video/media';

import useIsTrackEnabled from '@/hooks/video/isTrackEnabled';
import useMediaStreamTrack from '@/hooks/video/mediaStreamTrack';

const initializeAnalyser = (stream) => {
    const audioSource = audioContext.value.createMediaStreamSource(stream);

    const analyser = audioContext.value.createAnalyser();
    analyser.smoothingTimeConstant = 0.2;
    analyser.fftSize = 1024;

    audioSource.connect(analyser);
    return analyser;
};

export default function useAudioLevelBooleanIndicator(audioTrack) {
    const isTrackEnabled = useIsTrackEnabled(audioTrack);
    const mediaStreamTrack = useMediaStreamTrack(audioTrack);
    const analyser = shallowRef(null);

    const state = reactive({
        volume: 0,
    });

    const isTalking = computed(() => isTrackEnabled.value && state.volume >= 15);

    const setVolume = (newVolume) => {
        state.volume = newVolume;
    };

    watchEffect((onInvalidate) => {
        if (audioTrack.value && mediaStreamTrack.value && isTrackEnabled.value) {
            // Here we create a new MediaStream from a clone of the mediaStreamTrack.
            // A clone is created to allow multiple instances of this component for a single
            // AudioTrack on iOS Safari.
            let newMediaStream = new MediaStream([mediaStreamTrack.value.clone()]);

            // Here we listen for the 'stopped' event on the audioTrack. When the audioTrack is stopped,
            // we stop the cloned track that is stored in 'newMediaStream'. It is important that we stop
            // all tracks when they are not in use. Browsers like Firefox don't let you create a new stream
            // from a new audio device while the active audio device still has active tracks.
            const stopAllMediaStreamTracks = () =>
                newMediaStream.getTracks().forEach((track) => track.stop());
            audioTrack.value.on('stopped', stopAllMediaStreamTracks);

            const reinitializeAnalyser = () => {
                stopAllMediaStreamTracks();
                newMediaStream = new MediaStream([mediaStreamTrack.value.clone()]);
                analyser.value = initializeAnalyser(newMediaStream);
            };

            analyser.value = initializeAnalyser(newMediaStream);

            // Here we reinitialize the AnalyserNode on focus to avoid an issue in Safari
            // where the analysers stop functioning when the user switches to a new tab
            // and switches back to the app.
            window.addEventListener('focus', reinitializeAnalyser);

            onInvalidate(() => {
                stopAllMediaStreamTracks();
                window.removeEventListener('focus', reinitializeAnalyser);
                audioTrack.value?.off('stopped', stopAllMediaStreamTracks);
            });
        }
    });

    watchEffect((onInvalidate) => {
        if (isTrackEnabled.value && analyser.value) {
            const sampleArray = new Uint8Array(analyser.value.frequencyBinCount);

            const timer = interval(() => {
                analyser.value.getByteFrequencyData(sampleArray);
                let values = 0;

                const length = sampleArray.length;
                for (let i = 0; i < length; i++) {
                    values += sampleArray[i];
                }

                setVolume(values / length);
            }, 125);

            onInvalidate(() => {
                timer.stop();
            });
        }
    });

    return {
        ...toRefs(state),
        isTalking
    };
}
