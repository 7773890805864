import { ref, shallowRef } from 'vue';
import { connect as TwilioConnect } from 'twilio-video';
import { token, setRoomDetails, isHost } from '@/store/auth';
import apiClient from '@/libraries/axios';

import useParticipantNotifications from '@/hooks/video/participantNotifications';
import useRestartAudioTrackOnDeviceChange from '@/hooks/video/restartAudioTrackOnDeviceChange';
import useDisconnection from '@/hooks/video/disconnection';
import { connectOptions } from '@/hooks/options';

import { isMobile } from '@/hooks/utils';

import {
    localAudioTrack,
    localDataTrack,
} from '@/store/video/localTracks';
import { addError } from '@/store/errors';

const room = shallowRef(null);
const isConnected = ref(false);

const setRoom = (newRoom) => {
    room.value = newRoom;
};

const setIsConnected = (newIsConnected) => {
    isConnected.value = newIsConnected;
};

const roomTitle = (title) => {
    return apiClient
        .post('room/title', { title: title })
        .then((response) => {
            setRoomDetails(response);

            return Promise.resolve();
        })
        .catch((error) => {
            console.log(error);

            return Promise.reject(error.response);
        });
};

const roomPassword = () => {
    return apiClient
        .post('room/password')
        .then((response) => {
            setRoomDetails(response);

            return Promise.resolve();
        })
        .catch((error) => {
            console.log(error);

            return Promise.reject(error.response);
        });
};

const connect = () => {
    return TwilioConnect(token.value, {
        name: token.value,
        audio: false,
        video: false,
        tracks: [localDataTrack.value],
        ...connectOptions,
    }).then((twilioRoom) => {
        if (import.meta.env.MODE === 'development' && isHost.value) {
            const script = document.createElement('script');
            script.src = 'https://cdn.jsdelivr.net/npm/@twilio/video-room-monitor/dist/browser/twilio-video-room-monitor.min.js';
            script.onload = () => {
                // Register your Twilio Video Room here
                window.Twilio.VideoRoomMonitor.registerVideoRoom(twilioRoom);
                window.Twilio.VideoRoomMonitor.openMonitor();
            };

            document.body.appendChild(script);
        }

        setRoom(twilioRoom);

        room.value.once('disconnected', () => {
            setTimeout(() => setRoom(null));
            window.removeEventListener('beforeunload', () => syncDisconnect());

            if (isMobile) {
                window.removeEventListener('pagehide', () => syncDisconnect());
            }
        });

        twilioRoom.setMaxListeners(30);

        useRestartAudioTrackOnDeviceChange(localAudioTrack);
        useParticipantNotifications(room);
        useDisconnection(room);

        setIsConnected(true);

        window.addEventListener('beforeunload', () => syncDisconnect());
        if (isMobile) {
            window.addEventListener('pagehide', () => syncDisconnect());
        }
    }).catch((error) => {
        console.log(error);
        addError('connect', error.message);

        return Promise.reject(error.response);
    });
};

const disconnect = () => {
    return new Promise((resolve) => {
        syncDisconnect();

        return resolve();
    }).catch((error) => {
        console.log(error);

        return Promise.reject(error.response);
    });
};

// This is made synchronous so on page refresh it will execute without issue
const syncDisconnect = () => {
    if (room.value && isConnected.value) {
        setIsConnected(false);
        room.value.disconnect();
    }
};

export {
    room,
    isConnected,
    roomTitle,
    roomPassword,
    connect,
    disconnect
};
