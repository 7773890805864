import { watchEffect, shallowRef } from 'vue';

export default function useTrack(publication) {
    const track = shallowRef(publication.value && publication.value.track);

    const setTrack = (newTrack) => {
        track.value = newTrack;
    };

    watchEffect((onInvalidate) => {
        setTrack(publication.value && publication.value.track);

        if (publication.value) {
            const removeTrack = () => setTrack(null);

            publication.value.on('subscribed', setTrack);
            publication.value.on('unsubscribed', removeTrack);

            onInvalidate(() => {
                publication.value?.off('subscribed', setTrack);
                publication.value?.off('unsubscribed', removeTrack);
            });
        }
    });

    return track;
}
