import { computed, shallowRef, watchEffect } from 'vue';

import { getDeviceInfo } from '@/hooks/utils';

export default function useDevices() {
    const audioInputDevices = shallowRef([]);
    const videoInputDevices = shallowRef([]);
    const audioOutputDevices = shallowRef([]);
    const hasAudioInputDevices = computed(() => !!audioInputDevices.value);
    const hasVideoInputDevices = computed(() => !!videoInputDevices.value);

    const setAudioInputDevices = (newAudioInputDevices) => {
        audioInputDevices.value = newAudioInputDevices;
    };

    const setVideoInputDevices = (newVideoInputDevices) => {
        videoInputDevices.value = newVideoInputDevices;
    };

    const setAudioOutputDevices = (newAudioOutputDevices) => {
        audioOutputDevices.value = newAudioOutputDevices;
    };

    const getDevices = () =>
        getDeviceInfo().then((devices) => {
            setAudioInputDevices(devices.audioInputDevices);
            setVideoInputDevices(devices.videoInputDevices);
            setAudioOutputDevices(devices.audioOutputDevices);
        });

    watchEffect((onInvalidate) => {
        navigator.mediaDevices.addEventListener('devicechange', getDevices);

        onInvalidate(() => {
            navigator.mediaDevices.removeEventListener('devicechange', getDevices);
        });
    });

    return {
        audioInputDevices,
        videoInputDevices,
        audioOutputDevices,
        hasAudioInputDevices,
        hasVideoInputDevices,
        getDevices,
    };
}
