import { reactive, toRefs } from 'vue';

const AUDIO_SEED_SIZE = 50;

const seedAudio = (() => {
    const elements = [];
    for (let i = 0; i < AUDIO_SEED_SIZE; i++) {
        const audio = new Audio();
        audio.id = i;
        audio.autoplay = true;
        audio.taken = false;
        elements.push(audio);
    }

    return elements;
})();

const state = reactive({
    allowMediaPlayback: false,
    audioElements: seedAudio,
    audioContext: null,
});

const { allowMediaPlayback, audioContext } = toRefs(state);

const setAllowMediaPlayback = (newAllowMediaPlayback) => {
    state.allowMediaPlayback = newAllowMediaPlayback;
};

const getAudioElement = () => {
    const audio = state.audioElements.find((e) => e.taken === false);
    audio.taken = true;

    return audio;
};

const releaseAudioElement = (audioElement) => {
    audioElement.pause();
    audioElement.removeAttribute('src');
    audioElement.load();

    state.audioElements.find((e) => e === audioElement).taken = false;
};

const playSound = (sound) => {
    const audio = getAudioElement();

    const soundEnded = () => {
        audio.removeEventListener('ended', soundEnded);

        releaseAudioElement(audio);
    };

    audio.addEventListener('ended', soundEnded);
    audio.src = sound;
    audio.play();
};

const grabAudioContext = () => {
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    const audioContext = new AudioContext();

    state.audioContext = audioContext || new AudioContext();
};

const allowAudio = () => {
    // Play an empty buffer
    const source = state.audioContext.createBufferSource();
    source.buffer = state.audioContext.createBuffer(1, 1, 22050);
    source.connect(state.audioContext.destination);

    if (typeof source.start === 'undefined') {
        source.noteOn(0);
    } else {
        source.start(0);
    }

    source.onended = () => source.disconnect(0);

    if (typeof state.audioContext.resume === 'function') {
        state.audioContext.resume();
    }

    state.audioElements.forEach((e) => e.load());
};

const enableAllowMediaPlayback = () => {
    setAllowMediaPlayback(true);
    grabAudioContext();

    allowAudio();
};

export {
    allowMediaPlayback,
    enableAllowMediaPlayback,
    audioContext,
    getAudioElement,
    releaseAudioElement,
    playSound,
    allowAudio,
};
