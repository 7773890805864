import { computed } from 'vue';

import { room } from '@/store/video/room';
import { username } from '@/store/auth';

import useParticipants from '@/hooks/video/participants';
import useIdentity from '@/hooks/video/identity';

const participants = useParticipants(room);
const localParticipant = computed(() => room.value?.localParticipant);
const roomHost = computed(() => {
    if (
        localParticipant.value && useIdentity(localParticipant.value).usertype === 'host'
    ) {
        return localParticipant.value;
    }

    return participants.value.find((p) => useIdentity(p).usertype === 'host');
});
const webParticipants = computed(() =>
    participants.value.filter(
        (p) => useIdentity(p).parentUser === undefined || (p).parentUser === null || useIdentity(p).parentUser === username
    )
);
const localParticipantChild = computed(() => 
    participants.value.find((p) => 
        useIdentity(p).parentUser === username.value
    )
);
const totalParticipants = computed(() => participants.value.length);
const totalWebParticipants = computed(() => webParticipants.value.length);

const findChildParticipant = (parentUser) => {
    return computed(() =>
        participants.value.find((p) => useIdentity(p).parentUser === parentUser)
    );
};

export {
    participants,
    localParticipant,
    roomHost,
    webParticipants,
    localParticipantChild,
    totalParticipants,
    totalWebParticipants,
    findChildParticipant
};
