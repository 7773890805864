import { computed, ref } from 'vue';

import Cookies from 'js-cookie';
import apiClient from '@/libraries/axios';
import { jwtDecode } from '@/hooks/utils';

const auth = ref(null);

const tokenRef = ref(null);
const token = computed({
    get: () => {
        const currentToken = tokenRef.value || Cookies.get('token') || null;
        if (!currentToken) {
            return null;
        }

        const jwt = jwtDecode(currentToken);
        if (jwt.exp - Math.floor(Date.now() / 1000) > 0) {
            return currentToken;
        }

        Cookies.remove('token', { secure: true, sameSite: 'strict' });

        return null;
    },
    set: (token) => {
        if (token) {
            tokenRef.value = token;
            Cookies.set('token', token, { secure: true, sameSite: 'strict' });
        } else {
            tokenRef.value = null;
            Cookies.remove('token', { secure: true, sameSite: 'strict' });
        }
    },
});

const roomDetails = ref(null);
const username = computed(() => auth.value?.username);
const usertype = computed(() => auth.value?.usertype);
const userPassword = computed(() => auth.value?.password);
const parentUser = computed(() => auth.value?.parentUser);
const isHost = computed(() => auth.value?.usertype === 'host');

const setAuth = (newAuth) => {
    auth.value = newAuth;
};

const setToken = (newToken) => {
    token.value = newToken;
};

const setRoomDetails = (newRoomDetails) => {
    roomDetails.value = newRoomDetails;
};

const tokenNew = (credentials) => {
    return apiClient
        .post('auth/new', JSON.stringify(credentials))
        .then((response) => {
            setAuth(response.user);
            setToken(response.token);
            setRoomDetails(response.room);

            return Promise.resolve();
        })
        .catch((error) => {
            console.log(error);

            tokenClear();

            return Promise.reject(error.response);
        });
};

const tokenClear = () => {
    return new Promise((resolve) => {
        setAuth(null);
        setToken(null);
        setRoomDetails(null);

        return resolve();
    }).catch((error) => {
        console.log(error);

        return Promise.reject(error.response);
    });
};

const me = () => {
    return apiClient
        .post('auth/me')
        .then((response) => {
            setAuth(response.user);
            setToken(response.token);
            setRoomDetails(response.room);

            return Promise.resolve();
        })
        .catch((error) => {
            console.log(error);

            return Promise.reject(error.response);
        });
};

export {
    auth,
    username,
    usertype,
    userPassword,
    roomDetails,
    setRoomDetails,
    parentUser,
    token,
    isHost,
    tokenNew,
    tokenClear,
    me,
};
