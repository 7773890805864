import { reactive, watchEffect, toRef } from 'vue';

export default function useParticipantIsReconnecting(participant) {
    const state = reactive({
        isReconnecting: false,
    });

    const setIsReconnecting = (newIsReconnecting) => {
        state.isReconnecting = newIsReconnecting;
    };

    watchEffect((onInvalidate) => {
        if (!participant.value) return;

        const handleReconnecting = () => setIsReconnecting(true);
        const handleReconnected = () => setIsReconnecting(false);

        handleReconnected(); // Reset state when there is a new participant

        participant.value.on('reconnecting', handleReconnecting);
        participant.value.on('reconnected', handleReconnected);

        onInvalidate(() => {
            participant.value?.off('reconnecting', handleReconnecting);
            participant.value?.off('reconnected', handleReconnected);
        });
    });

    return toRef(state, 'isReconnecting');
}
