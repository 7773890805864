import { reactive, computed, shallowRef, toRefs } from 'vue';

import {
    createLocalAudioTrack,
    createLocalVideoTrack,
    createLocalTracks,
    LocalDataTrack,
} from 'twilio-video';

import { audioId, videoId, setAudioId, setVideoId } from '@/store/settings';
import { addError } from '@/store/errors';

import {
    removeUndefineds,
    getDeviceInfo,
    isPermissionDenied,
} from '@/hooks/utils';

export default function useLocalTracks() {
    const localAudioTrack = shallowRef(null);
    const localVideoTrack = shallowRef(null);
    const localDataTrack = shallowRef(new LocalDataTrack({
        ordered: true
    }));

    const state = reactive({
        isAcquiringLocalTracks: false,
        hasAcquiredLocalTracks: false,
    });

    const localMediaTracks = computed(() =>
        [localAudioTrack.value, localVideoTrack.value].filter(
            (track) => track !== null
        )
    );

    const setLocalAudioTrack = (newLocalAudioTrack) => {
        localAudioTrack.value = newLocalAudioTrack;
    };

    const setLocalVideoTrack = (newLocalVideoTrack) => {
        localVideoTrack.value = newLocalVideoTrack;
    };

    const setLocalDataTrack = (newLocalDataTrack) => {
        localDataTrack.value = newLocalDataTrack;
    };

    const setIsAquiringLocalTracks = (newIsAquiringLocalTracks) => {
        state.isAcquiringLocalTracks = newIsAquiringLocalTracks;
    };

    const setHasAcquiredLocalTracks = (newHasAcquiredLocalTracks) => {
        state.hasAcquiredLocalTracks = newHasAcquiredLocalTracks;
    };

    const getLocalAudioTrack = (deviceId = false, options = {}) => {
        if (localAudioTrack.value) return localAudioTrack;

        if (deviceId) {
            options.deviceId = { exact: deviceId };
        }

        setIsAquiringLocalTracks(true);

        return createLocalAudioTrack(removeUndefineds(options))
            .then((newTrack) => {
                setLocalAudioTrack(newTrack);
                return newTrack;
            })
            .finally(() => setIsAquiringLocalTracks(false));
    };

    const removeLocalAudioTrack = (localParticipant) => {
        localAudioTrack.value?.stop();

        if (localParticipant.value) {
            unpublishTrack(localParticipant.value, localAudioTrack.value);
        }

        setLocalAudioTrack(null);
    };

    const getLocalVideoTrack = async (options = {}) => {
        if (localVideoTrack.value) return localVideoTrack;

        const { videoInputDevices } = await getDeviceInfo();

        const hasSelectedVideoDevice = videoInputDevices.some(
            (device) => videoId.value && device.deviceId === videoId.value
        );

        setIsAquiringLocalTracks(true);

        return createLocalVideoTrack(
            removeUndefineds({
                ...options,
                name: `camera-${Date.now()}`,
                ...(hasSelectedVideoDevice && { deviceId: { exact: videoId.value } }),
            })
        )
            .then((newTrack) => {
                setLocalVideoTrack(newTrack);
                return newTrack;
            })
            .finally(() => setIsAquiringLocalTracks(false));
    };

    const removeLocalVideoTrack = (localParticipant) => {
        localVideoTrack.value?.stop();

        if (localParticipant.value) {
            unpublishTrack(localParticipant.value, localVideoTrack.value);
        }

        setLocalVideoTrack(null);
    };

    const getLocalDataTrack = (options = {}) => {
        if (localDataTrack.value) return localDataTrack;

        const localDataTrack = new LocalDataTrack(removeUndefineds(options));
        setLocalDataTrack(localDataTrack);
        return localDataTrack;
    };

    const removeLocalDataTrack = (localParticipant) => {
        if (localDataTrack.value) {
            // You cannot stop a datatrack, so unpublishing it does the job
            unpublishTrack(localParticipant.value, localDataTrack.value);
        }

        setLocalDataTrack(null);
    };

    const unpublishTrack = (localParticipant, localTrack) => {
        const localTrackPublication = localParticipant.unpublishTrack(localTrack);

        // Since the localParticipant doesnt emit trackUnpublished for some reason, this is a shit fix!
        // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
        if (localTrackPublication) {
            localParticipant.emit('trackUnpublished', localTrackPublication);
            localTrackPublication.unpublish();
        }
    };

    const getLocalMediaTracks = async (options = {}) => {
        const {
            audioInputDevices,
            videoInputDevices,
            hasAudioInputDevices,
            hasVideoInputDevices,
        } = await getDeviceInfo();

        if (!hasAudioInputDevices && !hasVideoInputDevices)
            return Promise.resolve();
        if (state.isAcquiringLocalTracks || localAudioTrack.value || localVideoTrack.value)
            return Promise.resolve();

        setIsAquiringLocalTracks(true);

        const hasSelectedAudioDevice = audioInputDevices.some(
            (device) => audioId.value && device.deviceId === audioId.value
        );
        const hasSelectedVideoDevice = videoInputDevices.some(
            (device) => videoId.value && device.deviceId === videoId.value
        );

        // In Chrome, it is possible to deny permissions to only audio or only video.
        // If that has happened, then we don't want to attempt to acquire the device.
        const isCameraPermissionDenied = await isPermissionDenied('camera');
        const isMicrophonePermissionDenied = await isPermissionDenied('microphone');

        const shouldAcquireVideo = options.video && hasVideoInputDevices && !isCameraPermissionDenied;
        const shouldAcquireAudio = options.audio && hasAudioInputDevices && !isMicrophonePermissionDenied;

        return createLocalTracks(
            removeUndefineds({
                ...options,
                audio: shouldAcquireAudio && {
                    ...options.audio,
                    name: options.audioName || `audio-${Date.now()}`,
                    ...(hasSelectedAudioDevice ? { deviceId: { exact: audioId.value } } : hasAudioInputDevices)
                },
                video: shouldAcquireVideo && {
                    ...options.video,
                    name: options.videoName || `camera-${Date.now()}`,
                    ...(hasSelectedVideoDevice && { deviceId: { exact: videoId.value } })
                }
            })
        )
            .then((tracks) => {
                const localAudioTrack = tracks.find((track) => track.kind === 'audio');
                const localVideoTrack = tracks.find((track) => track.kind === 'video');

                if (localAudioTrack) {
                    setLocalAudioTrack(localAudioTrack);

                    setAudioId(
                        localAudioTrack.mediaStreamTrack.getSettings().deviceId ?? null
                    );
                }

                if (localVideoTrack) {
                    setLocalVideoTrack(localVideoTrack);

                    setVideoId(
                        localVideoTrack.mediaStreamTrack.getSettings().deviceId ?? null
                    );
                }

                if (isCameraPermissionDenied && isMicrophonePermissionDenied) {
                    const error = new Error();
                    error.name = 'NotAllowedError';
                    throw addError('localTracks', error);
                }

                if (isCameraPermissionDenied) {
                    setVideoId(null);

                    throw addError('localTracks', new Error('CameraPermissionsDenied'));
                }

                if (isMicrophonePermissionDenied) {
                    setAudioId(null);

                    throw addError(
                        'localTracks',
                        new Error('MicrophonePermissionsDenied')
                    );
                }
            })
            .catch((error) => {
                setAudioId(null);
                setVideoId(null);

                throw addError('localTracks', error);
            })
            .finally(() => {
                setIsAquiringLocalTracks(false);
                setHasAcquiredLocalTracks(true);
            });
    };

    return {
        ...toRefs(state),
        localAudioTrack,
        localVideoTrack,
        localDataTrack,
        localMediaTracks,
        getLocalAudioTrack,
        removeLocalAudioTrack,
        getLocalVideoTrack,
        removeLocalVideoTrack,
        getLocalDataTrack,
        removeLocalDataTrack,
        getLocalMediaTracks,
    };
}
