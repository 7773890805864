<template>
    <div class='logo'>
        <svg
            class='logo'
            :class='[classArray]'
            :style='[styleArray]'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 447 393'
        >
            <path
                class='ring'
                d='M267 171c60 105 90 201 66 215s-93-60-154-164C119 117 89 20 113 6c24-13 93 60 154 165z'
                fill='none'
                stroke-width='7.99'
                stroke-miterlimit='10'
            />
            <path
                class='ring'
                d='M333 6c24 14-6 111-66 216-61 104-130 178-154 164s6-110 66-215C240 66 309-7 333 6z'
                fill='none'
                stroke-width='7.99'
                stroke-miterlimit='10'
            />
            <path
                class='ring'
                d='M443 192c0 27-99 50-220 50S4 219 4 192c0-28 98-51 219-51s220 23 220 51z'
                fill='none'
                stroke-width='7.99'
                stroke-miterlimit='10'
            />
            <path
                class='blue-circle'
                d='M140 100a18 18 0 11-36 0 18 18 0 0136 0z'
                stroke-width='7.99'
            />
            <path
                class='blue-circle'
                d='M376 231a18 18 0 11-37 0 18 18 0 0137 0z'
                stroke-width='7.99'
            />
            <path
                class='red-circle'
                d='M210 329a18 18 0 11-36 0 18 18 0 0136 0z'
                stroke-width='7.99'
            />
        </svg>
    </div>
</template>

<script>
import { computed } from 'vue';

export default {
    name: 'Logo',
    props: {
        theme: {
            type: String,
            default: 'normal',
            required: false,
        },
        width: {
            type: String,
            default: '165px',
            required: false,
        },
        fixed: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const classArray = computed(() => {
            const classes = [props.theme];

            return classes;
        });

        const styleArray = computed(() => {
            const styles = {};
            if (props.fixed) {
                styles.width = styles.height = props.width;
            } else {
                styles.maxWidth = styles.maxHeight = props.width;
            }

            return styles;
        });

        return {
            classArray,
            styleArray,
        };
    },
};
</script>

<style lang="scss">
.logo {
    text-align: center;
    outline: none;

    & svg {
        width: 100%;
        height: 100%;

        &.normal {
            & .ring {
                stroke: #000;
            }

            & .blue-circle {
                fill: #21409a;
                stroke: #000;
            }

            & .red-circle {
                fill: #bf2026;
                stroke: #000;
            }
        }

        &.black {
            & .ring {
                stroke: #000;
            }

            & .blue-circle {
                fill: #000;
                stroke: #000;
            }

            & .red-circle {
                fill: #000;
                stroke: #000;
            }
        }

        &.white {
            & .ring {
                stroke: #fff;
            }

            & .blue-circle {
                fill: #fff;
                stroke: #fff;
            }

            & .red-circle {
                fill: #fff;
                stroke: #fff;
            }
        }
    }
}
</style>
