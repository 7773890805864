<template>
    <div id='watermark' class='d-flex flex-column'>
        <Logo />

        <h3 class='watermark-text px-3 m-0'>
            <slot />
        </h3>
    </div>
</template>

<script>
import Logo from '@/components/partials/Logo.vue';

export default {
    name: 'Watermark',
    components: {
        Logo,
    },
};
</script>

<style lang="scss">
#watermark {
    & .logo {
        padding: 1rem;
    }
}

@media (width >= 768px) {
    #watermark .watermark-text {
        padding-bottom: 1rem;
    }
}

@media (width <= 768px) {
    #watermark {
        & .logo {
            display: none;
        }
    }
}

</style>
