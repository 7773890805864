<template>
    <div
        class='user'
        :class='{ talking: isTalking, reconnecting: isReconnecting }'
    >
        <span class='avatar' :class='[avatarClass]' :style='[avatarStyle]'>
            <span class='avatar-text' :style='[fontStyle]'>
                {{ text }}

                <div
                    v-if='isReconnecting'
                    class='spinner-border text-dark'
                    role='reconnecting'
                >
                    <span class='sr-only' />
                </div>
            </span>

            <span
                v-if='$slots.badge'
                class='avatar-badge'
                :class='[badgeClass]'
                :style='[badgeStyle]'
            >
                <slot name='badge' />
            </span>
        </span>

        <div v-if='$slots.details' class='d-flex flex-column flex-grow-1'>
            <slot name='details' />
        </div>
    </div>
</template>

<script>
import { computed } from 'vue';

import color from '@/hooks/color';

const RX_NUMBER = /^[0-9]*\.?[0-9]+$/;

const FONT_SIZE_SCALE = 0.4;
const BADGE_FONT_SIZE_SCALE = FONT_SIZE_SCALE * 0.7;

const DEFAULT_SIZES = {
    sm: '1.5em',
    md: '2.5em',
    lg: '3.5em',
};

const initials = (name) => {
    const allNames = name.trim().split(' ');
    const initials = allNames.reduce((acc, curr, index) => {
        if (index === 0 || index === allNames.length - 1) {
            acc = `${acc}${curr.charAt(0).toUpperCase()}`;
        }

        return acc;
    }, '');

    return initials;
};

const computeSize = (value) => {
    value =
        value === undefined || value === null || value === ''
            ? 'md'
            : typeof value === 'string' && RX_NUMBER.test(value)
                ? isNaN(parseFloat(value))
                    ? 0
                    : parseFloat(value)
                : value;

    return typeof value === 'number'
        ? `${value}px`
        : DEFAULT_SIZES[value] || value;
};

export default {
    name: 'User',
    props: {
        identity: {
            type: Object,
            required: true,
        },
        isTalking: {
            type: Boolean,
            default: false,
        },
        isReconnecting: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: '60px',
        },
        badgeVariant: {
            type: String,
            default: 'primary',
        },
        badgeTop: {
            type: Boolean,
            default: false,
        },
        badgeLeft: {
            type: Boolean,
            default: false,
        },
        badgeOffset: {
            type: String,
            default: '0px',
        }
    },
    setup(props, { slots }) {
        const text = initials(props.identity.username);
        const userColor = new color(props.identity.username);

        const computedSize = computed(() => computeSize(props.size));
        const fontStyle = computed(() => {
            const fontSize = computedSize.value
                ? `calc(${computedSize.value} * ${FONT_SIZE_SCALE})`
                : null;
            return fontSize
                ? { fontSize, color: userColor.textColor }
                : { color: userColor.textColor };
        });

        const badgeStyle = computed(() => {
            const offset = props.badgeOffset || '0px';

            return {
                fontSize: computedSize.value
                    ? `calc(${computedSize.value} * ${BADGE_FONT_SIZE_SCALE} )`
                    : null,
                top: props.badgeTop ? offset : null,
                bottom: props.badgeTop ? null : offset,
                left: props.badgeLeft ? offset : null,
                right: props.badgeLeft ? null : offset,
            };
        });

        const badgeClass = computed(() => {
            const classes = [`bg-${props.badgeVariant}`];

            return classes;
        });

        const avatarClass = computed(() => {
            const classes = [];

            if (slots.details) {
                classes.push('me-2');
            }

            return classes;
        });

        const avatarStyle = computed(() => {
            return {
                width: computedSize.value,
                height: computedSize.value,
                backgroundColor: userColor.backgroundColor,
            };
        });

        return {
            text,
            avatarStyle,
            avatarClass,
            badgeClass,
            badgeStyle,
            fontStyle,
        };
    },
};
</script>

<style lang="scss">
.user {
    display: flex;
    align-items: center;

    & .avatar {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        flex-shrink: 0;
        font-size: inherit;
        line-height: 1;
        max-width: 100%;
        max-height: auto;
        text-align: center;
        overflow: visible;
        position: relative;
        border-radius: 50%;

        &.btn,
        &[href] {
            padding: 0;
            border: 0;
        }

        & .avatar-text {
            border-radius: inherit;
            width: 100%;
            height: 100%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            // https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
            mask-image: radial-gradient(white, black);
            text-transform: uppercase;
            white-space: nowrap;
            user-select: none;

            & .spinner-border {
                position: absolute;
                width: inherit;
                height: inherit;
            }
        }

        & .avatar-badge {
            position: absolute;
            min-height: 1.5em;
            min-width: 1.5em;
            padding: 0.25em;
            line-height: 1;
            border-radius: 10em;
            font-size: 70%;
            font-weight: 700;
            z-index: 5;
        }
    }

    &.talking .avatar {
        box-shadow: 0 0 0 3px #90ee90;
    }

    &.reconnecting .avatar {
        opacity: 0.3;
    }
}
</style>
