<template>
    <div id='chat'>
        <div id='chat-wrapper'>
            <SimpleBar ref='chatMessages' class='chat-messages'>
                <template v-if='messages.length'>
                    <div
                        v-for='(message, index) in messages'
                        :key='index'
                        :class='messageOwner(message)'
                        class='chat-message'
                    >
                        <div class='chat-header'>
                            <div class='chat-message-content'>
                                <div class='d-flex w-100 justify-content-between'>
                                    <h5 class='mb-1'>{{ message.username }}</h5>
                                </div>
        
                                <p class='mb-0 me-2'>
                                    {{ message.message }}
                                </p>
                            </div>
        
                            <div class='chat-message-created-at'>
                                {{ format(message.created_at, "en_US", { relativeTime: real }) }}
                            </div>
                        </div>
        
                        <div class='chat-avatar'>
                            <User :identity='message' size='45px' />
                        </div>
                    </div>
                </template>
                <p v-else class='mt-2'>Nobody has written any messages yet.</p>
            </SimpleBar>

            <Transition name='unread'>
                <button
                    v-if='!bumpScrollbar && unread'
                    type='button'
                    class='unread rounded-pill btn btn-secondary btn-sm'
                    @click='bumpToBottom'
                >
                    {{ unreadMessage }}
                </button>
            </Transition>
        </div>

        <form class='chat-input' novalidate @submit.prevent='onSubmit'>
            <div class='input-group me-1'>
                <input
                    v-model.trim='message'
                    class='form-control shadow-none'
                    type='text'
                    placeholder='Type your message...'
                    autocomplete='off'
                    @keypress='onMessageInput'
                >
            </div>

            <button
                type='button'
                class='btn btn-send ms-2'
                :class='{ "btn-primary": message && charactersLeft, "btn-secondary": !isConnected || !message || !charactersLeft, "disabled": !isConnected || !message}'
                @click='onSubmit'
            >
                <IconBase view-box='0 0 18 18'><AirplaneFill /></IconBase>
            </button>
        </form>
    </div>
</template>

<script>
import { ref, reactive, computed, toRefs, watch, nextTick, onMounted } from 'vue';
import { messages, messageSend } from '@/store/chat';
import { username } from '@/store/auth';
import { real } from '@/store/ticker';
import { isConnected } from '@/store/video/room';
import { localParticipant } from '@/store/video/participants';

import { format } from 'timeago.js';

import User from '@/components/partials/User.vue';
import SimpleBar from '@/components/partials/SimpleBar.vue';
import IconBase from '@/components/partials/IconBase.vue';
import AirplaneFill from '@/components/icons/AirplaneFill.vue';

export default {
    name: 'Chat',
    components: {
        User,
        SimpleBar,
        IconBase,
        AirplaneFill
    },
    setup() {
        onMounted(() => bumpToBottom());

        const chatMessages = ref(null);
        const chatInput = ref(null);

        const state = reactive({
            bumpScrollbar: true,
            isBumping: false,
            message: '',
            unread: 0
        });

        const charactersLeft = computed(() =>
            Math.max(
                0,
                import.meta.env.VITE_MAX_CHAT_MESSAGE_LENGTH - state.message.length
            )
        );

        const bumpToBottom = () => {
            state.unread = 0;

            nextTick(() => {
                state.isBumping = true;
                chatMessages.value.scrollElement.scrollTop = chatMessages.value.scrollElement.scrollHeight;
                state.isBumping = false;
            });
        };

        watch(messages, () => {
            state.bumpScrollbar = 
                Math.abs(
                    chatMessages.value.scrollElement.clientHeight + chatMessages.value.scrollElement.scrollTop - chatMessages.value.scrollElement.scrollHeight
                ) < 1 || state.isBumping;

            if (state.bumpScrollbar) {
                bumpToBottom();
            } else {
                state.unread++;
            }
        });

        const unreadMessage = computed(() => {
            if (state.unread === 0) {
                return 'No Unread Messages';
            }

            return state.unread + ' Unread ' + (state.unread === 1 ? 'Message' : 'Messages');
            
        });

        function messageOwner(message) {
            return message.username === username.value ? 'my-message' : 'user-message';
        }

        function onMessageInput(e) {
            if (!charactersLeft.value) {
                if ([46, 8, 27, 13, 110].includes(e.keyCode) || ((e.keyCode == 65 || e.keyCode == 67) && (e.ctrlKey === true || e.metaKey === true)) || (e.keyCode >= 35 && e.keyCode <= 40)) {
                    return;
                }

                e.preventDefault();
            }
        }

        function onSubmit() {
            if (isConnected.value && state.message) {
                const message = {
                    sid: localParticipant.value.sid,
                    username: username.value,
                    message: state.message,
                    created_at: new Date(),
                };

                messageSend(message).then(function () {
                    state.message = '';

                    bumpToBottom();
                });
            }
        }

        return {
            ...toRefs(state),
            isConnected,
            messages,
            chatMessages,
            chatInput,
            onMessageInput,
            charactersLeft,
            onSubmit,
            unreadMessage,
            bumpToBottom,
            messageOwner,
            real,
            format,
        };
    },
};
</script>

<style lang="scss">
#chat,
#chat-wrapper {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 100%;
}

.chat-messages {
    flex: 1 1 1px;
    min-height: 100%;

    & .list-group {
        border-radius: 0.5rem;
    }
}

.chat-message {
    display: flex;
    flex-shrink: 0;
    width: 100%;
    padding: .5rem .5rem 0 0;
    margin-bottom: 0.25rem;
    word-wrap: anywhere;
    white-space: pre-wrap;
}

.chat-header {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin: 0 0.5rem;
}

.chat-message-content {
    padding: 0.5rem 0 0.5rem 0.5rem;
    margin-bottom: 0.25rem;
    border-radius: 0.5rem;
}

.chat-avatar {
    display: flex;
    align-items: flex-end;
}

.user-message {
    align-self: start;
    flex-direction: row-reverse;

    & .chat-message-content {
        color: #000;
        background-color: #e4e4e2;
        border-bottom-left-radius: 0;
    }
}

.my-message {
    align-self: end;

    & .chat-message-created-at {
        text-align: right;
    }

    & .chat-message-content {
        background-color: #0c71e3;
        border-bottom-right-radius: 0;
    }
}

.chat-input {
    display: flex;
    align-items: center;
    margin: 0.5rem;
    padding: 0.5rem;
    background-color: #fff;
    border-radius: 0.25rem;

    & .btn-send {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        z-index: 2;

        & svg {
            position: relative;
            right: 5px;
            bottom: 1px;
        }
    }

    & .form-control {
        border: none;
        border-radius: 0;
        height: auto;
        font-size: 1.1em;
    }
}

.unread {
    transition: opacity 0.2s ease !important;
    position: absolute;
    bottom: 0.5rem;
    align-self: center;
}

.unread-enter-from,
.unread-leave-to {
    opacity: 0;
}

</style>
