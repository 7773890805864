<template>
    <User
        v-if='username && usertype'
        :identity='{ username, usertype }'
        :is-talking='isTalking'
        :is-reconnecting='isReconnecting'
        size='45px'
    />
</template>

<script>
import { computed, watch } from 'vue';

import { username, usertype } from '@/store/auth';
import {
    localParticipant,
    roomHost,
    localParticipantChild,
    totalParticipants,
} from '@/store/video/participants';

import useTrack from '@/hooks/video/track';
import usePublications from '@/hooks/video/publications';
import useAttachVisibilityHandler from '@/hooks/video/attachVisibilityHandler';
import useParticipantIsReconnecting from '@/hooks/video/participantReconnecting';
import useAudioLevelIndicator from '@/hooks/video/audioLevelIndicator';
import useDataTrack from '@/hooks/video/dataTrack';
import useIsUserActive from '@/hooks/userActive';
import useLogout from '@/hooks/logout';

import User from '@/components/partials/User.vue';

export default {
    name: 'LocalParticipant',
    components: {
        User,
    },
    setup() {
        useAttachVisibilityHandler();

        const { audioPublication: primaryAudioPublication } =
            usePublications(localParticipant);
        const { audioPublication: childAudioPublication } = usePublications(
            localParticipantChild
        );

        const audioPublication = computed(() =>
            childAudioPublication.value
                ? childAudioPublication.value
                : primaryAudioPublication.value
        );

        const audioTrack = useTrack(audioPublication);

        const isReconnecting = useParticipantIsReconnecting(localParticipant);

        const { dataTrackSignal } = useDataTrack();

        const { isTalking } = useAudioLevelIndicator(audioTrack);
        watch(isTalking, () => {
            if (!localParticipant.value || !audioPublication.value) return;

            dataTrackSignal({ type: 'isTalking', data: isTalking.value });
        });

        const isUserActive = useIsUserActive();
        watch(isUserActive, () => {
            let inactive = false;

            if (roomHost.value) {
                if (!totalParticipants.value) {
                    inactive = true;
                }
            } else {
                inactive = true;
            }

            if (inactive && !isUserActive.value) {
                useLogout('You have been disconnected due to inactivity.');
            }
        });

        return {
            username,
            usertype,
            isTalking,
            isReconnecting,
        };
    },
};
</script>
