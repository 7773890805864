<template>
    <div
        class='video-controls'
        :class='{ "controls-hidden": !activity }'
        @click='hasActivity'
        @touchmove='hasActivity'
        @mousemove='hasActivity'
    >
        <transition name='controls-fade'>
            <div v-if='activity' class='controls-wrapper'>
                <div class='controls-content'>
                    <div class='controls'>
                        <div class='btn-group' role='group'>
                            <button type='button' class='btn btn-dark' @click='toggleVideo'>
                                <IconBase class='h2 mb-0'>
                                    <CameraVideoFill v-if='videoVisible' />
                                    <CameraVideoFillOff v-else />
                                </IconBase>
                            </button>
                            <button
                                v-if='!isLocal'
                                type='button'
                                class='btn btn-dark'
                                @click='toggleAudio'
                            >
                                <IconBase class='h2 mb-0'>
                                    <VolumeUpFill v-if='audioVisible' />
                                    <VolumeMuteFill v-else />
                                </IconBase>
                            </button>
                        </div>
                    </div>

                    <div class='controls'>
                        <button
                            v-if='!isMobile && supportsFullScreen'
                            type='button'
                            class='btn btn-dark'
                            @click='toggleFullScreen'
                        >
                            <IconBase class='h2 mb-0'>
                                <Fullscreen v-if='!isFullScreen' />
                                <FullscreenExit v-else />
                            </IconBase>
                        </button>

                        <button
                            v-if='isMobile && supportsFacingMode'
                            type='button'
                            class='btn btn-dark ml-2'
                            @click='toggleFacingMode'
                        >
                            <IconBase class='h2 mb-0'>
                                <CameraFlip />
                            </IconBase>
                        </button>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue';

import IconBase from '@/components/partials/IconBase.vue';
import Fullscreen from '@/components/icons/Fullscreen.vue';
import FullscreenExit from '@/components/icons/FullscreenExit.vue';
import CameraVideoFill from '@/components/icons/CameraVideoFill.vue';
import CameraVideoFillOff from '@/components/icons/CameraVideoFillOff.vue';
import VolumeUpFill from '@/components/icons/VolumeUpFill.vue';
import VolumeMuteFill from '@/components/icons/VolumeMuteFill.vue';
import CameraFlip from '@/components/icons/CameraFlip.vue';

import {
    supportsFullScreen,
    isFullScreen,
    toggleFullScreen,
} from '@/store/fullscreen';
import { activity, hasActivity } from '@/store/controlActivity';

import useFlipCamera from '@/hooks/video/flipCamera';
import { isMobile } from '@/hooks/utils';

export default {
    name: 'VideoControls',
    components: {
        IconBase,
        CameraVideoFill,
        CameraVideoFillOff,
        VolumeUpFill,
        VolumeMuteFill,
        CameraFlip,
        Fullscreen,
        FullscreenExit,
    },
    props: {
        audioVisible: {
            type: Boolean,
            default: true,
        },
        videoVisible: {
            type: Boolean,
            default: true,
        },
        isLocal: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:audioVisible', 'update:videoVisible'],
    setup(props, { emit }) {
        const state = reactive({
            lastAudioToggle: 0,
            lastVideoToggle: 0,
        });

        function toggleAudio() {
            if (Date.now() - state.lastAudioToggle > 200) {
                state.lastAudioToggle = Date.now();
                emit('update:audioVisible', !props.audioVisible);
            }
        }

        function toggleVideo() {
            if (Date.now() - state.lastVideoToggle > 200) {
                state.lastVideoToggle = Date.now();
                emit('update:videoVisible', !props.videoVisible);
            }
        }

        const { supportsFacingMode, toggleFacingMode } = useFlipCamera();

        return {
            ...toRefs(state),
            activity,
            hasActivity,
            isMobile,
            toggleAudio,
            toggleVideo,
            supportsFacingMode,
            toggleFacingMode,
            isFullScreen,
            toggleFullScreen,
            supportsFullScreen,
        };
    },
};
</script>

<style lang="scss">
.controls-fade-enter-active,
.controls-fade-leave-active {
    transition: opacity 0.25s ease;
}

.controls-fade-enter-from,
.controls-fade-leave-to {
    opacity: 0;
}

.video-controls {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    & .controls-wrapper {
        display: flex;
        align-items: center;
        height: 55px;
        padding: 0 0.5rem;
        background: rgb(122 130 136 / 70%);

        & .controls-content {
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            width: 100%;
            max-width: 600px;

            & .controls {
                display: flex;
            }
        }
    }
}

.controls-hidden {
    cursor: none;
}
</style>
