<template>
    <div id='center-layout' class='layout'>
        <div class='container-fluid'>
            <div class='row'>
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CenterLayout',
};
</script>
