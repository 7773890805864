import { watchEffect } from 'vue';

export default function useRestartAudioTrackOnDeviceChange(localAudioTrack) {
    watchEffect((onInvalidate) => {
        const handleDeviceChange = () => {
            if (localAudioTrack.value?.mediaStreamTrack.readyState === 'ended') {
                localAudioTrack.value.restart({});
            }
        };

        navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange);

        onInvalidate(() => {
            navigator.mediaDevices.removeEventListener(
                'devicechange',
                handleDeviceChange
            );
        });
    });
}
