import { localAudioTrack } from '@/store/video/localTracks';
import useIsTrackEnabled from '@/hooks/video/isTrackEnabled';

export default function useLocalAudioToggle() {
    const isLocalAudioEnabled = useIsTrackEnabled(localAudioTrack);

    const toggleLocalAudioEnabled = () => {
        if (localAudioTrack.value) {
            localAudioTrack.value.isEnabled
                ? localAudioTrack.value.disable()
                : localAudioTrack.value.enable();
        }
    };

    return { isLocalAudioEnabled, toggleLocalAudioEnabled };
}
