<template>
    <video
        ref='videoElement'
        :class='{ local: isLocal }'
        autopictureinpicture
        autoplay
        playsinline
        muted
    />
</template>

<script>
import { watchEffect, ref, toRef } from 'vue';

// import { isHost } from '@/store/auth';

import useTrack from '@/hooks/video/track';

export default {
    name: 'VideoTrack',
    props: {
        publication: {
            type: Object,
            required: true,
        },
        isLocal: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const videoTrack = useTrack(toRef(props, 'publication'));
        const videoElement = ref(null);

        watchEffect((onInvalidate) => {
            if (!videoTrack.value) return;

            videoTrack.value.attach(videoElement.value);

            // if (!isHost.value) {
            //     videoTrack.value.setContentPreferences({
            //         renderDimensions: { width: 1920, height: 1080 }
            //     });
            // }

            onInvalidate(() => {
                videoTrack.value && videoTrack.value.detach(videoElement.value);
            });
        });

        return {
            videoTrack,
            videoElement,
        };
    },
};
</script>

<style lang="scss">
video {
    transform-style: preserve-3d;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #000;
}
</style>
