<template>
    <MainLayout>
        <template #navbarDetails>
            <NavbarDetails />
        </template>

        <template #navbarActions>
            <Actions />
        </template>

        <template #navbarUser>
            <keep-alive>
                <LocalParticipant />
            </keep-alive>
        </template>

        <Prompt />

        <splitpanes id='livestream' class='g-0 default-theme' :horizontal='width <= 768' @resize='paneSize = $event[0].size'>
            <pane id='content' min-size='20' :max-size='isFullScreen ? 100 : 80' :size='isFullScreen ? 100 : paneSize'>
                <Settings />

                <Presentation />
            </pane>

            <pane v-if='!isFullScreen' id='sidebar' :min-size='isFullScreen ? 100 : 20' :size='isFullScreen ? 100 : (100 - paneSize)' max-size='80'>
                <Sidebar />
            </pane>
        </splitpanes>
    </MainLayout>
</template>

<script>
import { ref } from 'vue';

import { connect } from '@/store/video/room';
import { messagesSeed } from '@/store/chat';
import { isFullScreen } from '@/store/fullscreen';
import { setTheme } from '@/store/theme';

import useWidthHeight from '@/hooks/widthHeight';

import MainLayout from '@/components/layouts/MainLayout.vue';
import NavbarDetails from '@/components/partials/NavbarDetails.vue';
import Sidebar from '@/components/partials/Sidebar.vue';
import Actions from '@/components/partials/Actions.vue';
import Settings from '@/components/partials/Settings.vue';
import Presentation from '@/components/partials/Presentation.vue';
import LocalParticipant from '@/components/video/LocalParticipant.vue';
import Prompt from '@/components/partials/Prompt.vue';

import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';

export default {
    name: 'Livestream',
    components: {
        MainLayout,
        NavbarDetails,
        Sidebar,
        Actions,
        Settings,
        Presentation,
        LocalParticipant,
        Prompt,
        Splitpanes,
        Pane
    },
    setup() {
        setTheme('#212529');

        messagesSeed();

        connect();

        const { width } = useWidthHeight();

        return {
            paneSize: ref(80),
            width,
            isFullScreen
        };
    },
};
</script>

<style lang="scss">
@media (width <= 768px) {
    #main-layout #livestream {
        flex-flow: column;
    }
}

#main-layout {
    #content {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #sidebar {
        position: relative;
        display: flex;
        flex-grow: 1;
    }

    .splitpanes.default-theme {
        &.splitpanes--vertical > .splitpanes__splitter { /* stylelint-disable-line */
            width: 14px;
        }

        &.splitpanes--horizontal > .splitpanes__splitter { /* stylelint-disable-line */
            height: 14px;
        }

        & .splitpanes__pane { /* stylelint-disable-line */
            transition: none !important;
        }

        & .splitpanes__splitter { /* stylelint-disable-line */
            background-color: #212529 !important;
            border: none !important;

            &::before,
            &::after {
                background-color: #fff;
            }
        }
    }
}
</style>
