import { reactive, watchEffect, toRef } from 'vue';

export default function useIsTrackEnabled(track) {
    const state = reactive({
        isTrackEnabled: track.value ? track.value.isEnabled : false,
    });

    const setIsTrackEnabled = (newIsTrackEnabled) => {
        state.isTrackEnabled = newIsTrackEnabled;
    };

    watchEffect((onInvalidate) => {
        setIsTrackEnabled(track.value ? track.value.isEnabled : false);

        if (track.value) {
            const setTrackEnabled = () => setIsTrackEnabled(true);
            const setTrackDisabled = () => setIsTrackEnabled(false);

            track.value.on('enabled', setTrackEnabled);
            track.value.on('disabled', setTrackDisabled);

            onInvalidate(() => {
                track.value?.off('enabled', setTrackEnabled);
                track.value?.off('disabled', setTrackDisabled);
            });
        }
    });

    return toRef(state, 'isTrackEnabled');
}
