<template>
    <router-view v-slot='{ Component, route }'>
        <component :is='Component' :key='route.path' />
    </router-view>
</template>

<script>
import { watch } from 'vue';
import { roomDetails } from '@/store/auth';
import { theme } from '@/store/theme';
import { startTicker } from '@/store/ticker';

import useTokenValid from '@/hooks/tokenValid';

export default {
    name: 'App',
    setup() {
        startTicker();
        useTokenValid();

        watch(roomDetails, () => {
            if (roomDetails.value) {
                document.title = import.meta.env.VITE_TITLE + ' | ' + roomDetails.value.title;
            } else {
                document.title = import.meta.env.VITE_TITLE;
            }
        });

        watch(theme, () => {
            document.querySelector('meta[name="theme-color"]')?.setAttribute('content', theme.value);
        });
    },
};
</script>

<style lang="scss" src="@/styles/main.scss" />
<style lang="scss">
    ::-webkit-scrollbar {
        display: none;
    }

    html,
    body,
    #app {
        height: 100%;
    }

    body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .layout {
        display: grid;
        min-height: 100%;
        grid-template-rows: max-content 1fr;        
    }

    .nav-item {
        cursor: pointer;
    }
</style>
