<template>
    <Transition name='settings'>
        <div v-if='!isFullScreen && settingsVisible' id='settings'>
            <div id='settings-items' class='d-flex align-items-center p-2'>
                <button class='btn btn-outline-danger' @click='logout'>Logoff</button>

                <template v-if='isHost'>
                    <button class='btn btn-outline-warning' @click='roomPassword()'>
                        Regenerate Password
                    </button>
                    <div v-if='roomDetails' class='text-white'>Room Password: {{ roomDetails.password }}</div>
                    <div class='text-white'>Phone Number: {{ roomNumber }}</div>
                    <div class='text-white'>Phone Password: {{ userPassword }}</div>
                </template>
            </div>
        </div>
    </Transition>
</template>

<script>
import { isHost, roomDetails, userPassword } from '@/store/auth';
import { roomPassword } from '@/store/video/room';
import { settingsVisible } from '@/store/settings';
import { isFullScreen } from '@/store/fullscreen';

import useLogout from '@/hooks/logout';

export default {
    name: 'Settings',
    setup() {
        const logout = () => useLogout('You were logged out of the room');
        const roomNumber = import.meta.env.VITE_PHONE_NUMBER;

        return {
            logout,
            isHost,
            roomDetails,
            roomNumber,
            roomPassword,
            userPassword,
            settingsVisible,
            isFullScreen
        };
    },
};
</script>

<style lang="scss">
.settings-enter-from,
.settings-leave-active {
    transform: translateY(-100%);
}

#settings {
    transition: all 0.2s;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    background-color: #40464c;
    padding-left: env(safe-area-inset-left);

    & #settings-items {
        & > * {
            margin: 0 5px;
        }
    }
}
</style>
