import { watchEffect } from 'vue';

import useLogout from '@/hooks/logout';

import { unpublishLocalTracks } from '@/store/video/localTracks';

export default function useDisconnection(room) {
    watchEffect((onInvalidate) => {
        if (room.value) {
            const onDisconnected = (_, error) => {
                if (error) {
                    useLogout('You lost network connectivity to the room.');
                }

                unpublishLocalTracks(room.value.localParticipant);
            };

            room.value.on('disconnected', onDisconnected);

            onInvalidate(() => {
                room.value?.off('disconnected', onDisconnected);
            });
        }
    });
}
