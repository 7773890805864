import isPlainObject from '@/libraries/is-plain-object';

export const isMobile = (() => {
    if (
        typeof navigator === 'undefined' ||
    typeof navigator.userAgent !== 'string'
    ) {
        return false;
    }

    return /Mobile/.test(navigator.userAgent);
})();

export async function getDeviceInfo() {
    const devices = await navigator.mediaDevices.enumerateDevices();

    return {
        audioInputDevices: devices.filter((device) => device.kind === 'audioinput'),
        videoInputDevices: devices.filter((device) => device.kind === 'videoinput'),
        audioOutputDevices: devices.filter(
            (device) => device.kind === 'audiooutput'
        ),
        hasAudioInputDevices: devices.some(
            (device) => device.kind === 'audioinput'
        ),
        hasVideoInputDevices: devices.some(
            (device) => device.kind === 'videoinput'
        ),
    };
}

// This function will return 'true' when the specified permission has been denied by the user.
// If the API doesn't exist, or the query function returns an error, 'false' will be returned.
export async function isPermissionDenied(permissionName) {
    if (navigator.permissions) {
        try {
            const result = await navigator.permissions.query({ permissionName });
            return result.state === 'denied';
        } catch {
            return false;
        }
    } else {
        return false;
    }
}

export const jwtDecode = (token) => {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );
    
        return JSON.parse(jsonPayload);
    } catch(error) {
        return false;
    }
};

export const toFloat = (value, defaultValue = NaN) => {
    const float = parseFloat(value);
    return isNaN(float) ? defaultValue : float;
};

export const debounce = (callback, wait = 250) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => callback(...args), wait);
    };
};

export const removeUndefineds = (obj) => {
    if (!isPlainObject(obj)) return obj;

    const target = {};
    for (const key in obj) {
        const val = obj[key];
        if (typeof val !== 'undefined') {
            target[key] = removeUndefineds(val);
        }
    }

    return target;
};
