import { reactive, ref, watchEffect, toRef } from 'vue';
import throttle from 'lodash.throttle';

export default function useIsUserActive() {
    const state = reactive({
        isUserActive: true,
    });

    const timeoutIDRef = ref(0);
    const setIsUserActive = (newIsUserActive) => {
        state.isUserActive = newIsUserActive;
    };

    watchEffect((onInvalidate) => {
        const handleUserActivity = throttle(() => {
            setIsUserActive(true);
            clearTimeout(timeoutIDRef.value);
            const timeoutID = window.setTimeout(
                () => setIsUserActive(false),
                import.meta.env.VITE_INACTIVITY_TIME * 1000
            );
            timeoutIDRef.value = timeoutID;
        }, 1000);

        handleUserActivity();

        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('click', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);

        onInvalidate(() => {
            window.removeEventListener('mousemove', handleUserActivity);
            window.removeEventListener('click', handleUserActivity);
            window.removeEventListener('keydown', handleUserActivity);

            clearTimeout(timeoutIDRef.value);
        });
    });

    return toRef(state, 'isUserActive');
}
