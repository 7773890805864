import { toRefs, computed, reactive, markRaw } from 'vue';

import JoinPrompt from '@/components/prompts/JoinPrompt.vue';

const availablePrompts = {
    JoinPrompt: markRaw(JoinPrompt),
};

const state = reactive({
    prompt: null,
});

const { prompt } = toRefs(state);

const hasPrompt = computed(() => !!prompt.value);

const setPrompt = (selection) => {
    if (state.prompt === null || state.prompt.name !== selection) {
        state.prompt = availablePrompts[selection];
    }
};

const closePrompt = () => {
    state.prompt = null;
};

export { prompt, hasPrompt, setPrompt, closePrompt };
