<template>
    <svg
        xmlns='http://www.w3.org/2000/svg'
        :class='[classArray]'
        :style='[styleArray]'
        :width='width'
        :height='height'
        :viewBox='viewBox'
    >
        <g fill='currentColor'>
            <slot />
        </g>
    </svg>
</template>

<script>
import { computed } from 'vue';
import { toFloat } from '@/hooks/utils';

export default {
    name: 'IconBase',
    props: {
        viewBox: {
            type: String,
            default: '0 0 16 16',
        },
        width: {
            type: String,
            default: '1em',
        },
        height: {
            type: String,
            default: '1em',
        },
        fontScale: {
            type: [Number, String],
            default: 1,
        },
    },
    setup(props) {
        const fontScale = Math.max(toFloat(props.fontScale, 1), 0) || 1;

        const classArray = computed(() => {
            const classes = ['b-icon', 'bi'];

            return classes;
        });

        const styleArray = computed(() => {
            const styles = {};

            styles.fontSize = fontScale === 1 ? null : `${fontScale * 100}%`;

            return styles;
        });

        return {
            classArray,
            styleArray,
        };
    },
};
</script>

<style lang="scss">
.b-icon {
    &.bi {
        display: inline-block;
        overflow: visible;
        vertical-align: -0.15em;
    }
}

.btn,
.nav-link,
.dropdown-toggle,
.dropdown-item,
.input-group-text {
    .b-icon {
        &.bi {
            font-size: 125%;
            vertical-align: text-bottom;
        }
    }
}
</style>
