import { computed, reactive, toRef } from 'vue';
import { interval } from 'd3-timer';

const tickRate = import.meta.env.VITE_TICKER_INTERVAL * 1000;

const state = reactive({
    now: new Date(),
    interval: null,
});

const real = computed(() => {
    const nextTick = new Date(state.now.getTime() + 1000 * tickRate);
    const secondsUntilTick = parseInt(
        Math.abs((nextTick.getTime() - new Date().getTime()) / 1000)
    );
    return new Date(new Date().getTime() + secondsUntilTick);
});

const now = toRef(state, 'now');

const startTicker = () => {
    state.interval = interval(() => {
        refreshTicker();
    }, tickRate);
};

const stopTicker = () => {
    state.interval = state.now = null;
};

const refreshTicker = () => {
    state.now = new Date();
};

export {
    now,
    real,
    startTicker,
    stopTicker
};
