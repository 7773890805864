import { reactive, toRefs } from 'vue';

const state = reactive({
    settingsVisible: false,
    sidebar: (() => {
        const sidebar = JSON.parse(localStorage.getItem('sidebar'));
        return sidebar === null ? null : sidebar;
    })(),
    audioId: (() => {
        const audioId = localStorage.getItem('audioId');
        return audioId === null ? null : audioId;
    })(),
    videoId: (() => {
        const videoId = localStorage.getItem('videoId');
        return videoId === null ? null : videoId;
    })()
});

const {
    settingsVisible,
    sidebar,
    audioId,
    videoId
} = toRefs(state);

const setSidebar = (newSidebar) => {
    state.sidebar = newSidebar;
    localStorage.setItem('sidebar', newSidebar);
};

const setSettingsVisible = (newSettingsVisible) => {
    state.settingsVisible = newSettingsVisible;
};

const changeSettingsVisible = (isVisible = null) => {
    return new Promise((resolve) => {
        setSettingsVisible(isVisible !== null ? isVisible : !state.settingsVisible);
        return resolve(state.settingsVisible);
    }).catch((error) => {
        return Promise.reject(error.response);
    });
};

const changeSidebar = (setting) => {
    return new Promise((resolve) => {
        setSidebar(setting);
        return resolve(state.sidebar);
    }).catch((error) => {
        return Promise.reject(error.response);
    });
};

const setAudioId = (newAudioId) => {
    state.audioId = newAudioId;
    localStorage.setItem('audioId', newAudioId);
};

const setVideoId = (newVideoId) => {
    state.videoId = newVideoId;
    localStorage.setItem('videoId', newVideoId);
};

export {
    sidebar,
    settingsVisible,
    audioId,
    videoId,
    changeSidebar,
    changeSettingsVisible,
    setAudioId,
    setVideoId,
};
