const textColors = {
    BLACK: '000000',
    WHITE: 'ffffff',
};

// calculates contrast ratio between two hex strings
export function contrastRatioPair(hex1, hex2) {
    const lum1 = getLuminance(hex1);
    const lum2 = getLuminance(hex2);

    return (Math.max(lum1, lum2) + 0.05) / (Math.min(lum1, lum2) + 0.05);
}

// takes in hex string and converts to decimal number
function hexToDecimal(hex_string) {
    return parseInt(hex_string, 16);
}

/* converts a hex string to an object with 'r', 'g', 'b'
as the keys and their respective values */
function hexToRGB(hex) {
    const r = hexToDecimal(hex.substring(0, 2));
    const g = hexToDecimal(hex.substring(2, 4));
    const b = hexToDecimal(hex.substring(4, 6));

    return { r, g, b };
}

// calculates relative luminance given a hex string
function getLuminance(hex) {
    const rgb = hexToRGB(hex);

    for (const key in rgb) {
        let c = rgb[key];
        c /= 255;

        c = c > 0.03928 ? Math.pow((c + 0.055) / 1.055, 2.4) : (c /= 12.92);

        rgb[key] = c;
    }

    return 0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b;
}

function stringToColor(string) {
    let hash = 0;
    let i;
    let color = '';

    if (!string) {
        return color;
    }

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    color = '';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }

    return color;
}

// https://blog.karenying.com/posts/boost-visual-accessibility-by-auto-flipping-text-color

export default class color {
    // takes in hex string without '#'
    constructor(string) {
        this.bgColor = stringToColor(string);
    }

    // returns luminance as a number between 0 and 1
    get luminance() {
        return getLuminance(this.bgColor);
    }

    /* returns contrast ratio with a second color,
  calls contrastRatioPair */
    contrastRatioWith(hex2) {
        return contrastRatioPair(this.bgColor, hex2);
    }

    get backgroundColor() {
        return '#' + this.bgColor;
    }

    get textColor() {
        const { BLACK, WHITE } = textColors;

        return this.contrastRatioWith(BLACK) > this.contrastRatioWith(WHITE)
            ? '#' + BLACK
            : '#' + WHITE;
    }
}
