<template>
    <div id='users'>
        <SimpleBar class='user-list'>
            <ul v-if='webParticipants.length' class='list-group'>
                <li
                    v-for='webParticipant in webParticipants'
                    :key='webParticipant.sid'
                    class='list-group-item bg-transparent border-0 px-0 py-1'
                >
                    <Participant :participant='webParticipant' />
                </li>
            </ul>
            <p v-else class='mt-2'>Nobody else is currently in the livestream.</p>
        </SimpleBar>
    </div>
</template>

<script>
import { webParticipants } from '@/store/video/participants';

import Participant from '@/components/video/Participant.vue';
import SimpleBar from '@/components/partials/SimpleBar.vue';

export default {
    name: 'UserList',
    components: {
        Participant,
        SimpleBar
    },
    setup() {
        return {
            webParticipants,
        };
    },
};
</script>

<style lang="scss">
#users {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 100%;
}

.user-list {
    flex: 1 1 1px;
    min-height: 100%;
}
</style>
