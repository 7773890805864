import { ref, shallowRef } from 'vue';
import apiClient from '@/libraries/axios';
import useDataTrack from '@/hooks/video/dataTrack';
const { dataTrackSignal, monitorDataTrack } = useDataTrack();

const seeded = ref(false);
const messages = shallowRef([]);

const messagesSeed = () => {
    if (seeded.value) {
        return Promise.resolve();
    }

    return apiClient
        .get('room/chat/messages')
        .then((response) => {
            messages.value = response;

            seeded.value = true;

            return Promise.resolve();
        })
        .catch((error) => {
            console.log(error);

            return Promise.reject(error.response);
        });
};

const addMessage = (message) => {
    if (messages.value.length > import.meta.env.VITE_MAX_CHAT_MESSAGES) {
        messages.value.shift();
    }

    messages.value = [...messages.value, message];
};

const messageSend = (data) => {
    return Promise.all([
        apiClient.post('room/chat/message', { message: data.message }),
        dataTrackSignal({ type: 'chat', data: data})
    ]).then(() => {
        addMessage({ local: true, ...data });
    });
};

const messageReceive = (dataTrack) => {
    monitorDataTrack(dataTrack, { type: 'chat' }, (data) => {
        addMessage({
            local: false,
            ...data,
        });
    });
};

export {
    messages,
    messagesSeed,
    messageSend,
    messageReceive
};
