import { watchEffect } from 'vue';

import { playSound } from '@/store/video/media';

import { joinSound, leaveSound } from '@/hooks/sounds';
import useIdentity from '@/hooks/video/identity';

export default function useParticipantNotifications(room) {
    watchEffect((onInvalidate) => {
        if (!room.value) return;

        const participantEvent = (participant, sound) => {
            if (!['phone', 'hostPhone'].includes(useIdentity(participant).usertype)) {
                playSound(sound);
            }
        };

        const participantConnected = (participant) =>
            participantEvent(participant, joinSound);
        const participantDisconnected = (participant) =>
            participantEvent(participant, leaveSound);

        room.value.on('participantConnected', participantConnected);
        room.value.on('participantDisconnected', participantDisconnected);

        onInvalidate(() => {
            room.value?.off('participantConnected', participantConnected);
            room.value?.off('participantDisconnected', participantDisconnected);
        });
    });
}
