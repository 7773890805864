import { ref } from 'vue';

const theme = ref('#ffffff');

function setTheme(newTheme) {
    theme.value = newTheme;
}

export {
    theme,
    setTheme
};