const connectOptions = {
    networkQuality: { local: 1, remote: 1 },
    preferredVideoCodecs: 'auto',
    bandwidthProfile: {
        video: {
            mode: 'presentation',
            contentPreferencesMode: 'auto',
            trackSwitchOffMode: 'predicted',
            clientTrackSwitchOffControl: 'auto'
        },
    },
};

const publishingOptions = {
    user: {
        audio: {
            noiseCancellationOptions: {
                sdkAssetsPath: '/noisecancellation',
                vendor: 'krisp'
            }    
        },
        video: false,
    },
    host: {
        audio: true,
        audioName: 'host-audio',
        video: {
            width: { min: 1280, ideal: 1920, max: 4096 },
            height: { min: 720, ideal: 1080, max: 2160 },
            frameRate: { min: 15, ideal: 24, max: 30 },
        },
        videoName: 'host-video'
    },
};

if (import.meta.env.MODE === 'development') {
    publishingOptions.wsServer = 'wss://us2.vss.dev.twilio.com/signaling';    
}

export { connectOptions, publishingOptions };