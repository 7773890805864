import { reactive, toRef } from 'vue';
import useDataTrack from '@/hooks/video/dataTrack';

export default function useIsTalking(dataTrack) {
    const state = reactive({
        isTalking: false
    });

    const setIsTalking = (newIsTalking) => {
        state.isTalking = newIsTalking;
    };

    const { monitorDataTrack } = useDataTrack();
    monitorDataTrack(dataTrack, { type: 'isTalking' }, (isTalking) => {
        setIsTalking(isTalking);
    });

    return toRef(state, 'isTalking');
}
