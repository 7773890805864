<template>
    <CenterLayout>
        <div id='content' class='col'>
            <div id='notfound-wrapper' class='m-auto'>
                <div id='notfound-content' class='py-4'>
                    <Logo />
                    <h3 class='pt-4 w-100 text-center'>Page not found</h3>
                </div>
            </div>
        </div>
    </CenterLayout>
</template>

<script>
import CenterLayout from '@/components/layouts/CenterLayout.vue';
import Logo from '@/components/partials/Logo.vue';

export default {
    name: 'NotFound',
    components: {
        CenterLayout,
        Logo,
    },
};
</script>

<style lang="scss">
@media (width >= 576px) {
    #notfound-wrapper {
        width: 400px;
    }
}
</style>
