import { reactive, toRefs, watchEffect } from 'vue';

export default function useWidthHeight() {
    const state = reactive({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const setWidthHeight = (newWidth, newHeight) => {
        state.width = newWidth;
        state.height = newHeight;
    };

    watchEffect((onInvalidate) => {
        const handleResize = () => {
            setWidthHeight(
                window.innerWidth,
                window.innerHeight
            );
        };

        window.addEventListener('resize', handleResize);

        onInvalidate(() => {
            window.removeEventListener('resize', handleResize);
        });
    });

    return {
        ...toRefs(state)
    };
}
