<template>
    <div id='main-layout' class='layout'>
        <Navbar>
            <template v-for='(_, slot) of $slots' #[slot]='scope'>
                <slot :name='slot' v-bind='scope' />
            </template>
        </Navbar>

        <slot />
    </div>
</template>

<script>
import Navbar from '@/components/partials/Navbar.vue';

export default {
    name: 'MainLayout',
    components: {
        Navbar,
    },
};
</script>