import { reactive, watchEffect } from 'vue';

import useLocalVideoToggle from '@/hooks/video/localVideoToggle';
import { isMobile } from '@/hooks/utils';

import { localVideoTrack } from '@/store/video/localTracks';

export default function useAttachVisibilityHandler() {
    const toggleVideoEnabled = useLocalVideoToggle();

    const state = reactive({
        shouldRepublish: false,
    });

    const setShouldRepublish = (newShouldRepublish) => {
        state.shouldRepublish = newShouldRepublish;
    };

    watchEffect((onInvalidate) => {
        if (isMobile) {
            const handleVisibilityChange = () => {
                if (document.visibilityState === 'hidden' && localVideoTrack.value) {
                    setShouldRepublish(true);
                    toggleVideoEnabled();
                } else if (state.shouldRepublish) {
                    setShouldRepublish(false);
                    toggleVideoEnabled();
                }
            };

            document.addEventListener('visibilitychange', handleVisibilityChange);

            onInvalidate(() => {
                document.removeEventListener(
                    'visibilitychange',
                    handleVisibilityChange
                );
            });
        }
    });
}
