<template>
    <div class='card-group'>
        <div class='card'>
            <div class='card-body'>
                <h5 v-if='isHost' class='card-title'>Choose audio and video devices</h5>
                <h5 v-else class='card-title'>Choose a microphone</h5>

                <template v-if='!errorLocalTracks'>
                    <p v-if='isHost' class='card-text'>
                        You may choose your devices for the stream.
                    </p>
                    <p v-else class='card-text'>
                        You may choose a microphone to talk with everyone.
                    </p>
                </template>

                <div v-if='isAcquiringLocalTracks && !hasAcquiredLocalTracks && !errorLocalTracks' class='d-flex align-items-center mb-3'>
                    <div class='spinner-border' />
                    <span class='ms-2'>Waiting for device authorization</span>
                </div>
                <div v-else-if='hasAcquiredLocalTracks'>
                    <div v-if='errorLocalTracks?.message === "CameraPermissionsDenied"'>
                        The user has denied permission to use video.<br>Please grant permission to the browser to access the camera.
                    </div>
                    <div
                        v-else-if='errorLocalTracks?.message === "MicrophonePermissionsDenied"'
                    >
                        The user has denied permission to use audio.<br>Please grant permission to the browser to access the microphone.
                    </div>
                    <template v-else-if='errorLocalTracks?.name === "NotAllowedError"'>
                        <div
                            v-if='errorLocalTracks?.message === "Permission denied by system"'
                        >
                            The operating system has blocked the browser from accessing the microphone.<br>Please check your operating system settings.
                        </div>
                        <div v-else>
                            The user has denied permission to use any audio/video devices.<br>Please grant permission to the browser to access the devices of your choosing.
                        </div>
                    </template>
                    <div v-else-if='errorLocalTracks'>
                        The user has an unknown error occur when attempting to use audio/video.<br>Error message: {{ errorLocalTracks.message }}
                    </div>

                    <template v-if='!errorLocalTracks'>
                        <template v-if='audioInputDevices.length'>
                            <label for='audioInputDevice' class='form-label'>Microphone</label>
                            <select
                                id='audioInputDevice'
                                class='form-select mb-3'
                                @change='changeAudioDevice'
                            >
                                <option
                                    v-for='audioInputDevice in audioInputDevices'
                                    :key='audioInputDevice.deviceId'
                                    :value='audioInputDevice.deviceId'
                                    :selected='
                                        localAudioInputDeviceId === audioInputDevice.deviceId
                                    '>
                                    {{ audioInputDevice.label }}
                                </option>
                            </select>

                            <AudioLevel :audio-track='localAudioTrack' />
                        </template>
                        <div v-else class='mb-3'>
                            You do not have any available microphones.
                        </div>

                        <template v-if='isHost'>
                            <template v-if='videoInputDevices.length'>
                                <label for='videoInputDevices' class='form-label'>Video</label>
                                <select
                                    id='videoInputDevices'
                                    class='form-select mb-3'
                                    @change='changeVideoDevice'
                                >
                                    <option
                                        v-for='videoInputDevice in videoInputDevices'
                                        :key='videoInputDevice.deviceId'
                                        :value='videoInputDevice.deviceId'
                                        :selected='localVideoInputDeviceId === videoInputDevice.deviceId'>
                                        {{ videoInputDevice.label }}
                                    </option>
                                </select>
                            </template>
                            <div v-else class='mb-3'>
                                You do not have any available video devices.
                            </div>
                        </template>

                        <button type='button' class='btn btn-primary' @click='publishMedia()'>Start Stream</button>
                    </template>
                </div>

                <button
                    v-if='isHost && !hasAcquiredLocalTracks'
                    type='button'
                    class='btn btn-primary'
                    @click='fetchDevices()'
                >
                    Use Devices
                </button>
                <button
                    v-else-if='!isHost && !hasAcquiredLocalTracks'
                    type='button'
                    class='btn btn-primary'
                    @click='fetchDevices()'
                >
                    Use Microphone
                </button>
            </div>
        </div>

        <div class='card'>
            <div v-if='isHost' class='card-body'>
                <p class='card-text'>
                    Dial into the room to listen to all participants.
                </p>

                <p class='card-text mb-1'>On your phone, dial:</p>
                <h3>{{ roomNumber }}</h3>
                <p class='card-text mb-1'>Enter your password when prompted:</p>
                <h3>{{ userPassword }}</h3>
            </div>
            <template v-else>
                <div class='card-header'>
                    <ul class='nav nav-pills nav-fill card-header-pills'>
                        <li class='nav-item'>
                            <a class='nav-link' :class='{ active: phoneTab === 0 }' @click='phoneTab = 0'>Call me</a>
                        </li>
                        <li class='nav-item'>
                            <a class='nav-link' :class='{ active: phoneTab === 1 }' @click='phoneTab = 1'>Dial in</a>
                        </li>
                    </ul>
                </div>
                <div class='card-body tab-content'>
                    <div class='tab-pane' :class='{ active: phoneTab === 0 }'>
                        <template v-if='attemptingCall'>
                            <div
                                class='card-text d-flex align-items-center justify-content-between'>
                                <div class='d-flex align-items-center'>
                                    <div class='spinner-border ms-auto' />
                                    <span class='ms-2'>Calling {{ formattedNumber }}</span>
                                </div>
                                <div class='d-flex align-items-center'>
                                    <button type='button' class='btn btn-danger' @click='phoneEndCall'>
                                        <IconBase><TelephoneFill /></IconBase>
                                    </button>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <p class='card-text'>
                                We will call your phone and you will be able to use it as a microphone.<br>You will not hear any audio on your phone.
                            </p>
    
                            <form class='needs-validation' novalidate @submit.prevent='phoneCallMe'>
                                <div class='form-floating mb-3'>
                                    <input
                                        id='phoneNumber'
                                        v-model='formattedNumber'
                                        class='form-control form-control-lg'
                                        :class='{ "is-invalid": errorNumber }'
                                        type='text'
                                        placeholder='Phone number'
                                        autocomplete='off'
                                        inputmode='numeric'
                                        required
                                        @input='formatNumber()'
                                    >
                                    <label for='phoneNumber'>Phone number</label>
    
                                    <div class='invalid-feedback'>
                                        {{ errorNumber?.message }}
                                    </div>
                                </div>
    
                                <button type='submit' class='btn btn-primary'>Call me</button>
                            </form>
                        </template>
                    </div>
    
                    <div class='tab-pane' :class='{ active: phoneTab === 1 }'>
                        <p class='card-text'>
                            Dial into the room and use your phone as a microphone.<br>You will not hear any audio on your phone.
                        </p>
    
                        <p class='card-text mb-1'>On your phone, dial:</p>
                        <h3>{{ roomNumber }}</h3>
                        <p class='card-text mb-1'>Enter your password when prompted:</p>
                        <h3>{{ userPassword }}</h3>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { reactive, ref, toRefs, watch } from 'vue';

import useMediaStreamTrack from '@/hooks/video/mediaStreamTrack';
import { publishingOptions } from '@/hooks/options';

import { userPassword, isHost } from '@/store/auth';
import { callMe, endCall } from '@/store/video/phone';
import {
    localAudioTrack,
    localVideoTrack,
    fetchLocalTracks,
    publishLocalTracks,
    isAcquiringLocalTracks,
    hasAcquiredLocalTracks,
    audioInputDevices,
    videoInputDevices,
    getDevices,
} from '@/store/video/localTracks';
import { closePrompt } from '@/store/prompt';
import { localParticipant, localParticipantChild } from '@/store/video/participants';
import { audioId, videoId, setAudioId, setVideoId } from '@/store/settings';
import { addError, getError, clearError } from '@/store/errors';

import AudioLevel from '@/components/partials/AudioLevel.vue';
import IconBase from '@/components/partials/IconBase.vue';
import TelephoneFill from '@/components/icons/TelephoneFill.vue';

export default {
    name: 'JoinPrompt',
    components: {
        AudioLevel,
        IconBase,
        TelephoneFill
    },
    setup() {
        const localAudioMediaStreamTrack = useMediaStreamTrack(localAudioTrack);
        const localAudioInputDeviceId = ref(localAudioMediaStreamTrack.value?.getSettings().deviceId || audioId.value);

        const localVideoMediaStreamTrack = useMediaStreamTrack(localVideoTrack);
        const localVideoInputDeviceId = ref(localVideoMediaStreamTrack.value?.getSettings().deviceId || videoId.value);

        const roomNumber = import.meta.env.VITE_PHONE_NUMBER;
        const state = reactive({
            phoneTab: 0,
            formattedNumber: '',
            attemptingCall: false,
        });

        if (hasAcquiredLocalTracks.value) {
            fetchDevices();
        }

        function fetchDevices() {
            fetchLocalTracks().finally(() => getDevices());
        }

        function publishMedia() {
            if (isAcquiringLocalTracks.value || state.attemptingCall) return;

            publishLocalTracks(localParticipant).then(() => closePrompt());
        }

        function phoneCallMe() {
            const number = state.formattedNumber.replace(/\D/g, '');

            callMe(number).then(() => {
                clearError();
                state.attemptingCall = true;
            });
        }

        function phoneEndCall() {
            endCall();

            state.attemptingCall = false;
        }

        function formatNumber() {
            const parts = state.formattedNumber
                .replace(/\D/g, '')
                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            state.formattedNumber = !parts[2]
                ? parts[1]
                : '(' + parts[1] + ') ' + parts[2] + (parts[3] ? '-' + parts[3] : '');
        }

        function changeAudioDevice(event) {
            setAudioId(event.target.value);

            localAudioTrack.value
                ?.restart({
                    name: isHost.value ? publishingOptions.host.audioName : `audio-${Date.now()}`,
                    deviceId: { exact: event.target.value },
                })
                .catch((error) => {
                    throw addError('localTracks', error);
                });
        }

        function changeVideoDevice(event) {
            setVideoId(event.target.value);
            localVideoInputDeviceId.value = event.target.value;

            localVideoTrack.value
                ?.restart({
                    ...publishingOptions.host.video,
                    name: isHost.value ? publishingOptions.host.videoName : `camera-${Date.now()}`,
                    deviceId: { exact: videoId.value },
                })
                .catch((error) => {
                    throw addError('localTracks', error);
                });
        }

        watch(localParticipantChild, () => {
            if (localParticipantChild.value) {
                state.attemptingCall = false;

                if (!isHost.value) {
                    closePrompt();
                }
            }
        });

        const errorLocalTracks = getError('localTracks');
        const errorNumber = getError('number');

        return {
            ...toRefs(state),
            isHost,
            isAcquiringLocalTracks,
            hasAcquiredLocalTracks,
            audioInputDevices,
            videoInputDevices,
            localAudioTrack,
            localAudioInputDeviceId,
            localVideoInputDeviceId,
            fetchDevices,
            changeAudioDevice,
            changeVideoDevice,
            publishMedia,
            errorLocalTracks,
            errorNumber,
            phoneCallMe,
            phoneEndCall,
            formatNumber,
            roomNumber,
            userPassword
        };
    },
};
</script>
