import { publishingOptions } from '@/hooks/options';
import useLocalTracks from '@/hooks/video/localTracks';
import useDevices from '@/hooks/video/devices';

import { usertype } from '@/store/auth';

const {
    localAudioTrack,
    localVideoTrack,
    localDataTrack,
    localMediaTracks,
    getLocalMediaTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    removeLocalDataTrack,
    isAcquiringLocalTracks,
    hasAcquiredLocalTracks,
} = useLocalTracks();

const {
    audioInputDevices,
    videoInputDevices,
    audioOutputDevices,
    hasAudioInputDevices,
    hasVideoInputDevices,
    getDevices,
} = useDevices();

const fetchLocalTracks = (extraOptions = null) => {
    const options = { ...publishingOptions[usertype.value], extraOptions };

    return getLocalMediaTracks(options);
};

const publishLocalTracks = (localParticipant) => {
    return new Promise((resolve) => {
        localMediaTracks.value.forEach((track) => {
            localParticipant.value.publishTrack(track);
        });

        return resolve();
    }).catch((error) => {
        console.log(error);

        return Promise.reject(error.response);
    });
};

const unpublishLocalTracks = (localParticipant, target = null) => {
    return new Promise((resolve) => {
        if (localAudioTrack.value && (!target || target === 'audio')) {
            removeLocalAudioTrack(localParticipant);
        }

        if (localVideoTrack.value && (!target || target === 'video')) {
            removeLocalVideoTrack(localParticipant);
        }

        if (localDataTrack.value && target === 'data') {
            removeLocalDataTrack(localParticipant);
        }

        return resolve();
    }).catch((error) => {
        console.log(error);

        return Promise.reject(error.response);
    });
};

export {
    localAudioTrack,
    localVideoTrack,
    localDataTrack,
    localMediaTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    removeLocalDataTrack,
    isAcquiringLocalTracks,
    hasAcquiredLocalTracks,
    fetchLocalTracks,
    publishLocalTracks,
    unpublishLocalTracks,
    audioInputDevices,
    videoInputDevices,
    audioOutputDevices,
    hasAudioInputDevices,
    hasVideoInputDevices,
    getDevices,
};
