import { watchEffect, shallowRef } from 'vue';

export default function useMediaStreamTrack(track) {
    const mediaStreamTrack = shallowRef(track.value?.mediaStreamTrack);

    const setMediaStreamTrack = (newMediaStreamTrack) => {
        mediaStreamTrack.value = newMediaStreamTrack;
    };

    watchEffect((onInvalidate) => {
        setMediaStreamTrack(track.value?.mediaStreamTrack);

        if (track.value) {
            const handleStarted = () => setMediaStreamTrack(track.value.mediaStreamTrack);
            track.value.on('started', handleStarted);

            onInvalidate(() => {
                track.value?.off('started', handleStarted);
            });
        }
    });

    return mediaStreamTrack;
}
