<template>
    <User
        :identity='identity'
        :is-talking='audioPublication && isTalking'
        :is-reconnecting='isReconnecting'
        size='60px'
        :badge-variant='isMicrophoneActive ? "success" : "danger"'
        show-name
    >
        <template v-if='audioPublication' #badge>
            <IconBase v-if='!childParticipant'>
                <MicFill v-if='isMicrophoneActive' />
                <MicMuteFill v-else />
            </IconBase>
            <IconBase v-else>
                <TelephoneFill />
            </IconBase>
        </template>

        <template #details>
            <span class='text-white'>{{
                identity.username
            }}</span>

            <div v-if='isHost' class='btn-group btn-group-sm'>
                <button v-if='audioPublication && !childParticipant' :disabled='!isMicrophoneActive' type='button' class='btn btn-warning' @click='mute()'>Mute</button>
                <button type='button' class='btn btn-danger' @click='kick()'>{{ childParticipant ? 'End Call' : 'Kick' }}</button>
            </div>
        </template>
    </User>

    <AudioTrack
        v-if='identity.usertype !== "host" && audioPublication'
        :publication='audioPublication'
        :muted='isTalking'
    />
</template>

<script>
import { toRef, computed } from 'vue';

import User from '@/components/partials/User.vue';
import AudioTrack from '@/components/video/AudioTrack.vue';
import IconBase from '@/components/partials/IconBase.vue';
import MicFill from '@/components/icons/MicFill.vue';
import MicMuteFill from '@/components/icons/MicMuteFill.vue';
import TelephoneFill from '@/components/icons/TelephoneFill.vue';

import { messageReceive } from '@/store/chat';
import { findChildParticipant } from '@/store/video/participants';
import { isHost } from '@/store/auth';
import { killCall } from '@/store/video/phone';

import useIdentity from '@/hooks/video/identity';
import usePublications from '@/hooks/video/publications';
import useTrack from '@/hooks/video/track';
import useIsTalking from '@/hooks/video/isTalking';
import usePublicationIsTrackEnabled from '@/hooks/video/publicationIsTrackEnabled';
import useParticipantIsReconnecting from '@/hooks/video/participantReconnecting';
import useDataTrack from '@/hooks/video/dataTrack';

export default {
    name: 'Participant',
    components: {
        User,
        AudioTrack,
        IconBase,
        MicFill,
        MicMuteFill,
        TelephoneFill,
    },
    props: {
        participant: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const participant = toRef(props, 'participant');
        const identity = useIdentity(participant.value);

        const childParticipant = findChildParticipant(identity.username);

        const { audioPublication: primaryAudioPublication, dataPublication } = usePublications(participant);
        const { audioPublication: childAudioPublication } = usePublications(childParticipant);

        const audioPublication = computed(() =>
            childParticipant.value && childAudioPublication.value
                ? childAudioPublication.value
                : primaryAudioPublication.value
        );

        const dataTrack = useTrack(dataPublication);
        const { dataTrackSignal } = useDataTrack();

        function mute() {
            dataTrackSignal({type: 'mute', sid: participant.value.sid});
        }

        function kick() {
            if (childParticipant.value) {
                killCall(identity.username);
            } else {
                dataTrackSignal({type: 'kick', sid: participant.value.sid});
            }
        }

        messageReceive(dataTrack);

        const isTalking = useIsTalking(dataTrack);

        const isReconnecting = useParticipantIsReconnecting(participant);
        const isMicrophoneActive = usePublicationIsTrackEnabled(audioPublication);

        return {
            identity,
            isHost,
            isTalking,
            isMicrophoneActive,
            isReconnecting,
            audioPublication,
            childParticipant,
            mute,
            kick
        };
    },
};
</script>
