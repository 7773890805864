<template>
    <div id='room-details' class='d-md-flex d-none'>
        <template v-if='isHost'>
            <input
                v-if='roomDetails'
                type='text'
                class='editable'
                :value='roomDetails.title'
                @change='titleChange'
            >
        </template>
        <template v-else>
            <div v-if='roomDetails' class='navbar-brand'>{{ roomDetails.title }}</div>
        </template>

        <div class='navbar-text'>{{ totalUsers }}</div>
    </div>
</template>

<script>
import { computed } from 'vue';

import { isHost, roomDetails } from '@/store/auth';
import { roomTitle } from '@/store/video/room';
import { totalWebParticipants } from '@/store/video/participants';

import useDataTrack from '@/hooks/video/dataTrack';

export default {
    name: 'NavbarDetails',
    setup() {
        const { dataTrackSignal } = useDataTrack();

        function titleChange(e) {
            roomTitle(e.target.value).then(() => {
                e.target.blur();

                dataTrackSignal({type: 'roomDetails', data: roomDetails.value});
            });
        }

        const totalUsers = computed(() => {
            if (totalWebParticipants.value === 0) {
                return 'No Users';
            }

            return totalWebParticipants.value + ' ' + (totalWebParticipants.value === 1 ? 'User' : 'Users');
            
        });

        return {
            isHost,
            roomDetails,
            titleChange,
            totalUsers
        };
    },
};
</script>

<style lang="scss">
.editable {
    padding: 0;
    font-size: 1.125rem;
    border: none;
    background: none;
    color: #fff;

    &:focus {
        outline: none;
    }
}

#room-details {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    & > div {
        margin: 0;
        padding: 0;
    }
}
</style>
