import axios from 'axios';

import useLogout from '@/hooks/logout';

import { addError } from '@/store/errors';

const apiClient = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

apiClient.interceptors.response.use(
    function (response) {
        return response.data;
    },
    function (error) {
        if (error.response?.status === 401) {
            useLogout('You are not authenticated.');
        }

        if (error.response?.status === 422) {
            if (error.response.data) {
                for (const [type, messages] of Object.entries(
                    error.response.data
                )) {
                    addError(type, new Error(messages));
                }
            }
        }

        return Promise.reject(error);
    }
);

export default apiClient;
